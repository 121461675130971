<ng-template #fileCardContainer></ng-template>
<div class="result-container mat-elevation-z2 mt-2" *ngIf="!isTableView">
    <div *ngFor="let model of searchDataResults; let i = index;">
        <div class="search-result-card flex flex-grow py-2 mt-2"
             [ngClass]="{'card-active': model.fileStatus == 'A', 'card-inactive': model.fileStatus == 'W', 'card-suspend': model.fileStatus == 'S'}">
            <div class="flex flex-col flex-grow-0 justify-center authpage-separater">
                <div class="flex flex-row items-center px-2">
                    <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-2" name="isAuthPage"
                                  [disabled]="model.fileStatus == 'W' || model.fileStatus == 'S' || model.fileType == 'AGENCY'"
                                  (change)="onAuthPageClick($event, model.fileNumber, model)"
                                  [(ngModel)]="model.isAuthPage" id="{{'isAuthPage-' + model.fileRecId}}">
                    </mat-checkbox>
                </div>
            </div>
            <div class="flex flex-col flex-grow">
                <div class="flex flex-row items-center px-2">
                    <div class="flex my-2 flex-wrap cursor-pointer"
                         (click)="navigateToFileDetailPage(model.fileNumber, false, model)">
                        <ng-container *ngIf="model.fileNumber">
                            <span class="font-bold">File:&nbsp;</span>
                            <span>{{ model.fileNumber }}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="model.fileCcn">
                            <span class="font-bold">CCN:&nbsp;</span>
                            <span>{{ model.fileCcn }}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyName">
                            <span class="font-bold">Company Name:&nbsp;</span>
                            <span>{{ model.ownerCompanyName }}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="model.startDate">
                            <span class="font-bold">Start Date:&nbsp;</span>
                            <span>{{formatDate(model.startDate)}}</span>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="model.endDate">
                            <span class="font-bold">End Date:&nbsp;</span>
                            <span>{{formatDate(model.endDate)}}</span>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                    <ng-container>
                        <button (click)="indexExpanded = (indexExpanded == i?-1:i);" color="primary" class="text-black">
                            <fa-icon *ngIf="indexExpanded == i" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                            <fa-icon *ngIf="indexExpanded != i" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="model.complementaryCCN">
                        <span class="font-bold">Comp CCN:&nbsp;</span>
                        <span>{{ formatCompCCN(model.complementaryCCN) }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerAccount">
                        <span class="font-bold">Account Number:&nbsp;</span>
                        <span>{{ model.ownerAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.partyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Org Number:&nbsp;</span>
                        <span>{{ model.partyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">PartySite No:&nbsp;</span>
                        <span>{{ model.ownerPartySiteNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.wdrwlCode">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Withdrawal Code:&nbsp;</span>
                        <span>{{ model.wdrwlCode }}</span>
                    </ng-container>
                </div>
                <mat-divider *ngIf="indexExpanded == i"></mat-divider>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                    <ng-container *ngIf="model.controlNumbers">
                        <span class="font-bold">Control Number:&nbsp;</span>
                        <span>{{ formatControlNumber(model.controlNumbers) }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.lastUpdatedBy">
                        <span class="font-bold">Last Updated By:&nbsp;</span>
                        <span>{{ model.lastUpdatedBy }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.subscriberNumber">
                        <span class="font-bold">Subscriber Number:&nbsp;</span>
                        <span>{{ model.subscriberNumber }}</span>
                    </ng-container>
                    <span class="field-divider mx-2" *ngIf="model.lastUpdatedBy && model.ownerCompanyAddress1">&nbsp;</span>
                    <ng-container *ngIf="model.ownerCompanyAddress1">
                        <div class="font-bold">Company Address:&nbsp;</div>
                    </ng-container>
                    <div class="flex flex-column file-details-body flex-wrap">
                        <ng-container *ngIf="model.ownerCompanyAddress1">
                            <div>{{ model.ownerCompanyAddress1 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress2">
                            <div>{{ model.ownerCompanyAddress2 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress3">
                            <div>{{ model.ownerCompanyAddress3 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress4">
                            <div>{{ model.ownerCompanyAddress4 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress5">
                            <div>{{ model.ownerCompanyAddress5 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress6">
                            <div>{{ model.ownerCompanyAddress6 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCity">
                            <div>{{ model.ownerCity }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerProvince">
                            <div>{{ model.ownerProvince }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerState">
                            <div>{{ model.ownerState }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCountry">
                            <div>{{ model.ownerCountry }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerPostalCode">
                            <div>{{ model.ownerPostalCode }}</div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="flex flex-col flex-grow-0 justify-start">
                <div class="flex flex-row items-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="navigateToFileDetailPage(model.fileNumber, false, model)">
                            <mat-icon>visibility</mat-icon><span>View Details</span>
                        </button>
                        <button mat-menu-item (click)="editFileDetails(model)" *ngIf="isAdmin || isEdit" [disabled]="model.fileStatus == 'S'">
                            <mat-icon>edit</mat-icon><span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="addVolume(model)" *ngIf="isAdmin || isEdit"
                                [disabled]="model.fileStatus == 'W' || model.fileStatus == 'S'">
                            <mat-icon>add_circle_outline</mat-icon><span>Add Volume</span>
                        </button>
                        <button mat-menu-item [routerLink]="['/file-details/',model.fileRecId,'file-volume']">
                            <mat-icon>visibility</mat-icon><span>View Volume(s)</span>
                        </button>
                        <button mat-menu-item (click)="reactivateFile(model.fileRecId, model.fileNumber)" [disabled]="model.fileStatus == 'A'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': model.fileStatus == 'A' ? 'none' : 'block'}">
                            <mat-icon>replay</mat-icon><span>Re-Activate</span>
                        </button>
                        <button mat-menu-item (click)="withdrawFile(model.fileRecId)" [disabled]="model.fileStatus == 'S' || model.fileStatus == 'W'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (model.fileStatus == 'S' || model.fileStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>archive</mat-icon><span>Withdraw</span>
                        </button>
                        <button mat-menu-item (click)="suspendFile(model.fileRecId, model.fileNumber)" [disabled]="model.fileStatus == 'S' || model.fileStatus == 'W'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (model.fileStatus == 'S' || model.fileStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>report_off</mat-icon><span>Suspend</span>
                        </button>
                        <button mat-menu-item (click)="deleteFile(model.fileRecId)" [disabled]="model.fileStatus == 'S'"
                                *ngIf="isAdmin" [ngStyle]="{'display': (model.fileStatus == 'S') ? 'none' : 'block'}">
                            <mat-icon>delete_forever</mat-icon><span>Delete</span>
                        </button>
                        <button mat-menu-item (click)="ecmCreateFolder(model)" *ngIf="isAdmin && model.fileStatus == 'A'">
                            <mat-icon>create_new_folder</mat-icon><span>Create ECM Folder</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="result-container mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table
           [dataSource]="tableDataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
           [matSortDisableClear]="true" [matSortActive]="searchRequest.sortBy" [matSortDirection]="searchRequest.sortDirection">

        <ng-container matColumnDef="authPage">
            <th mat-header-cell *matHeaderCellDef class="table-header">Auth Page</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-2" name="isAuthPage"
                              [disabled]="element.fileStatus == 'W' || element.fileStatus == 'S' || element.fileType == 'AGENCY'"
                              (change)="onAuthPageClick($event, element.fileNumber, element)" [(ngModel)]="element.isAuthPage"
                              id="{{'isAuthPage-' + element.fileRecId}}">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="fileStatus">
            <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">Status</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <mat-icon *ngIf="element.fileStatus == 'A'" class="text-label-green font-bold">stop</mat-icon>
                    <mat-icon *ngIf="element.fileStatus == 'W'" class="text-label-dimgrey font-bold">stop</mat-icon>
                    <mat-icon *ngIf="element.fileStatus == 'S'" class="text-label-blue font-bold">stop</mat-icon>
                    <span *ngIf="element.fileStatus == 'A'"> Active</span>
                    <span *ngIf="element.fileStatus == 'W'"> Withdrawn</span>
                    <span *ngIf="element.fileStatus == 'S'"> Suspended</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fileNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>File Number</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fileNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="fileCcn">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>CCN</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fileCcn}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ownerCompanyName">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Company Name</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerCompanyName}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ownerAccount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Account Number</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerAccount}}
            </td>
        </ng-container>
        <ng-container matColumnDef="ownerPartySiteNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Party Site Number</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.ownerPartySiteNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="subscriberNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Subscriber Number</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.subscriberNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header">Options</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="navigateToFileDetailPage(element.fileNumber, false, element)">
                        <mat-icon>visibility</mat-icon><span>View Details</span>
                    </button>
                    <button mat-menu-item (click)="editFileDetails(element)" *ngIf="isAdmin || isEdit" [disabled]="element.fileStatus == 'S'">
                        <mat-icon>edit</mat-icon><span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="addVolume(element)" *ngIf="isAdmin || isEdit" 
                            [disabled]="element.fileStatus == 'W' || element.fileStatus == 'S'">
                        <mat-icon>add_circle_outline</mat-icon><span>Add Volume</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/file-details/',element.fileRecId,'file-volume']">
                        <mat-icon>visibility</mat-icon><span>View Volume(s)</span>
                    </button>
                    <button mat-menu-item (click)="reactivateFile(element.fileRecId, element.fileNumber)" [disabled]="element.fileStatus == 'A'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': element.fileStatus == 'A' ? 'none' : 'block'}">
                        <mat-icon>replay</mat-icon><span>Re-Activate</span>
                    </button>
                    <button mat-menu-item (click)="withdrawFile(element.fileRecId)" [disabled]="element.fileStatus == 'S' || element.fileStatus == 'W'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (element.fileStatus == 'S' || element.fileStatus == 'W') ? 'none' : 'block'}">
                        <mat-icon>archive</mat-icon><span>Withdraw</span>
                    </button>
                    <button mat-menu-item (click)="suspendFile(element.fileRecId, element.fileNumber)" [disabled]="element.fileStatus == 'S' || element.fileStatus == 'W'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (element.fileStatus == 'S' || element.fileStatus == 'W') ? 'none' : 'block'}">
                        <mat-icon>report_off</mat-icon><span>Suspend</span>
                    </button>
                    <button mat-menu-item (click)="deleteFile(element.fileRecId)" [disabled]="element.fileStatus == 'S'"
                            *ngIf="isAdmin" [ngStyle]="{'display': (element.fileStatus == 'S') ? 'none' : 'block'}">
                        <mat-icon>delete_forever</mat-icon><span>Delete</span>
                    </button>
                    <button mat-menu-item (click)="ecmCreateFolder(element)" *ngIf="isAdmin && element.fileStatus == 'A'">
                        <mat-icon>create_new_folder</mat-icon><span>Create ECM Folder</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.complementaryCCN">
                                <span class="font-bold">Comp CCN:&nbsp;</span>
                                <span>{{ formatCompCCN(element.complementaryCCN) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.controlNumbers">
                                <span class="font-bold">Control Number:&nbsp;</span>
                                <span>{{ formatControlNumber(element.controlNumbers) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.partyNumber">
                                <span class="font-bold">Org Number:&nbsp;</span>
                                <span>{{ element.partyNumber }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.startDate">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Start Date:&nbsp;</span>
                                <span>{{ formatDate(element.startDate) }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.endDate">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">End Date:&nbsp;</span>
                                <span>{{ formatDate(element.endDate) }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.wdrwlCode">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Withdrawal Code:&nbsp;</span>
                                <span>{{ element.wdrwlCode }}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.lastUpdatedBy">
                                <span class="font-bold">Last Updated By:&nbsp;</span>
                                <span>{{ element.lastUpdatedBy }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container>
                                <div class="font-bold">Company Address:&nbsp;</div>
                            </ng-container>
                            <div class="flex flex-column file-details-body flex-wrap">
                                <ng-container *ngIf="element.ownerCompanyAddress1">
                                    <div>{{ element.ownerCompanyAddress1 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress2">
                                    <div>{{ element.ownerCompanyAddress2 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress3">
                                    <div>{{ element.ownerCompanyAddress3 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress4">
                                    <div>{{ element.ownerCompanyAddress4 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress5">
                                    <div>{{ element.ownerCompanyAddress5 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCompanyAddress6">
                                    <div>{{ element.ownerCompanyAddress6 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCity">
                                    <div>{{ element.ownerCity }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerProvince">
                                    <div>{{ element.ownerProvince }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerState">
                                    <div>{{ element.ownerState }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCountry">
                                    <div>{{ element.ownerCountry }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerPostalCode">
                                    <div>{{ element.ownerPostalCode }}</div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="table-element-row"
            [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>