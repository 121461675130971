<ng-template #accFileVolSearchResultContainer></ng-template>
<div *ngIf="!isTableView">
    <div *ngFor="let model of searchDataResults; let i = index;">
        <div class="search-result-card flex flex-grow py-2 mt-1">
            <div class="flex flex-col flex-grow-0 justify-center authpage-separater">
                <div class="flex flex-row items-center px-2">
                    <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-1" name="isAuthPage" [(ngModel)]="model.isAuthPage" [disabled]="!isAuthPageEligible(model)" 
                        (change)="onAuthPageClick($event, model.fileNumber, model.fileVolume, model.fileVolRecID, model)" id="{{'isAuthPage-' + model.fileVolRecID}}" >
                    </mat-checkbox>
                </div>
            </div>
            <div class="flex flex-col flex-grow">
                <div class="flex flex-row px-2">
                    <div class="flex flex-wrap items-center cursor-pointer my-1">
                        <ng-container *ngIf="model.fileNumber">
                            <mat-icon *ngIf="model.fileStatus == 'A'" class="text-label-green font-bold" matTooltip="Active File">stop</mat-icon>
                            <mat-icon *ngIf="model.fileStatus == 'W'" class="text-label-dimgrey font-bold" matTooltip="In Active File">stop</mat-icon>
                            <mat-icon *ngIf="model.fileStatus == 'S'" class="text-label-blue font-bold" matTooltip="Suspended File">stop</mat-icon>
                            <span class="font-bold">File :&nbsp;</span>
                            <span>{{ model.fileNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.fileVolume">
                            <span class="field-divider mx-1">&nbsp;</span>
                            <mat-icon *ngIf="model.volumeStatus == 'A'" class="text-label-green font-bold" matTooltip="Active Volume">stop</mat-icon>
                            <mat-icon *ngIf="model.volumeStatus == 'W'" class="text-label-dimgrey font-bold" matTooltip="In Active Volume">stop</mat-icon>
                            <mat-icon *ngIf="model.volumeStatus == 'S'" class="text-label-blue font-bold" matTooltip="Suspended Volume">stop</mat-icon>
                            <span class="font-bold">Volume :&nbsp;</span>
                            <span>{{ model.fileVolume }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.basic_CCN">
                            <span class="field-divider mx-1">&nbsp;</span>
                            <span class="font-bold">CCN :&nbsp;</span>
                            <span>{{ model.basic_CCN }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.accountNumber">
                            <span class="field-divider mx-1">&nbsp;</span>
                            <span class="font-bold">Account No :&nbsp;</span>
                            <span>{{ model.accountNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.partySiteNo">
                            <span class="field-divider mx-1">&nbsp;</span>
                            <span class="font-bold">Party SiteNo :&nbsp;</span>
                            <span>{{ model.partySiteNo }}</span>
                        </ng-container>
                        <ng-container>
                            <span class="field-divider mx-1">&nbsp;</span>
                            <span *ngIf="!model.mfr_AssoRecID" class="font-bold">No</span>
                            <mat-icon *ngIf="model.mfr_AssoRecID && model.mfr_Status == 'A'" class="text-label-green" matTooltip="Active MFR">check_circle</mat-icon>
                            <mat-icon *ngIf="model.mfr_AssoRecID && model.mfr_Status == 'W'" class="text-label-dimgrey" matTooltip="In Active MFR">cancel</mat-icon>
                            <mat-icon *ngIf="model.mfr_AssoRecID && model.mfr_Status == 'S'" class="text-label-blue" matTooltip="Suspended MFR">cancel</mat-icon>
                            <span class="font-bold">&nbsp;MFR</span>
                        </ng-container>
                        <ng-container>
                            <span class="field-divider mx-1">&nbsp;</span>
                            <span *ngIf="!model.listeeRecID" class="font-bold">No</span>
                            <mat-icon *ngIf="model.listeeRecID && model.listeeStatus == 'Y'" class="text-label-green" matTooltip="Active Listee">check_circle</mat-icon>
                            <mat-icon *ngIf="model.listeeRecID && model.listeeStatus == 'N'" class="text-label-dimgrey" matTooltip="In Active Listee">cancel</mat-icon>
                            <mat-icon *ngIf="model.listeeRecID && model.listeeStatus == 'S'" class="text-label-blue" matTooltip="Suspended Listee">cancel</mat-icon>
                            <span class="font-bold">&nbsp;Listee</span>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row result-details-body flex-wrap items-center my-1 ml-2 px-2">
                    <ng-container>
                        <button (click)="indexExpanded = (indexExpanded == i?-1:i);" color="primary" class="text-black">
                            <fa-icon *ngIf="indexExpanded == i" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                            <fa-icon *ngIf="indexExpanded != i" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="model.listeeRecID">
                        <span class="font-bold">Listee File :&nbsp;</span>
                        <span>{{ model.listeeFile }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.fileType">
                        <span *ngIf="model.listeeRecID" class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">File Type :&nbsp;</span>
                        <span>{{ model.fileType }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.volumeType">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">Volume Type :&nbsp;</span>
                        <span>{{ model.volumeType }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfr_AssoRecID">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">MFR Type :&nbsp;</span>
                        <span *ngIf="model.mfr_AssoRecID">{{ getMfrAssoType(model.mfr_Type) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeeRecID">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">Listee Type :&nbsp;</span>
                        <span *ngIf="model.listeeRecID">{{ getListeeAssoType(model.listeeType) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.partyNumber">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">Party No :&nbsp;</span>
                        <span>{{ model.partyNumber }}</span>
                    </ng-container>
                </div>
                <mat-divider *ngIf="indexExpanded == i"></mat-divider>
                <div class="flex flex-row result-details-body flex-wrap items-center my-1 ml-2 px-2" *ngIf="indexExpanded == i">
                    <ng-container *ngIf="model.ownCompName">
                        <span class="font-bold">Owner Comp.Name :&nbsp;</span>
                        <span>{{ model.ownCompName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfr_AssoRecID">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">MFR Comp.Name :&nbsp;</span>
                        <span>{{ model.mfr_CompName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.listeeRecID">
                        <span class="field-divider mx-1">&nbsp;</span>
                        <span class="font-bold">Listee Comp.Name :&nbsp;</span>
                        <span>{{ model.listeeCompName }}</span>
                    </ng-container>
                </div>
            </div>
            <div class="flex flex-col flex-grow-0 justify-start">
                <div class="flex flex-row items-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options"><mat-icon>more_vert</mat-icon></button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="generateAuthPage(model)" [disabled]="!isAuthPageEligible(model)">
                            <mat-icon>assignment</mat-icon><span>Generate Auth.Page</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table [dataSource]="tableDataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
           [matSortDisableClear]="true" [matSortActive]="searchRequest.sortBy" [matSortDirection]="searchRequest.sortDirection">
        <ng-container matColumnDef="authPage">
            <th mat-header-cell *matHeaderCellDef class="table-header">&nbsp;</th>
            <td mat-cell *matCellDef="let element">
                <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-1 mx-2" name="isAuthPage" [(ngModel)]="element.isAuthPage" [disabled]="!isAuthPageEligible(element)"
                              (change)="onAuthPageClick($event, element.fileNumber, element.fileVolume, element.fileVolRecID, element)" id="{{'isAuthPage-' + element.fileVolRecID}}">
                </mat-checkbox>
            </td>
        </ng-container>
        <ng-container matColumnDef="fileNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>File Number</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <mat-icon *ngIf="element.fileStatus == 'A'" class="text-label-green font-bold" matTooltip="Active File">stop</mat-icon>
                    <mat-icon *ngIf="element.fileStatus == 'W'" class="text-label-dimgrey font-bold" matTooltip="In Active File">stop</mat-icon>
                    <mat-icon *ngIf="element.fileStatus == 'S'" class="text-label-blue font-bold" matTooltip="Suspended File">stop</mat-icon>
                    <span>{{ element.fileNumber }}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="fileVolume">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Volume</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <mat-icon *ngIf="element.volumeStatus == 'A'" class="text-label-green font-bold" matTooltip="Active Volume">stop</mat-icon>
                    <mat-icon *ngIf="element.volumeStatus == 'W'" class="text-label-dimgrey font-bold" matTooltip="In Active Volume">stop</mat-icon>
                    <mat-icon *ngIf="element.volumeStatus == 'S'" class="text-label-blue font-bold" matTooltip="Suspended Volume">stop</mat-icon>
                    <span>{{ element.fileVolume }}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="volumeType">
            <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">Volume Type</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                {{element.volumeType}}
            </td>
        </ng-container>
        <ng-container matColumnDef="fileCCN">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>CCN</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                {{element.basic_CCN}}
            </td>
        </ng-container>
        <ng-container matColumnDef="accountNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Account No</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                {{element.accountNumber}}
            </td>
        </ng-container>
        <ng-container matColumnDef="partySiteNo">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Party SiteNo</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                {{element.partySiteNo}}
            </td>
        </ng-container>
        <ng-container matColumnDef="listeeFile">
            <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">Listee File</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                {{element.listeeFile}}
            </td>
        </ng-container>
        <ng-container matColumnDef="listeeDetail">
            <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">Listee Detail</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <span *ngIf="!element.listeeRecID">No Listee</span>
                    <mat-icon *ngIf="element.listeeRecID && element.listeeStatus == 'Y'" class="text-label-green" matTooltip="Active Listee">check_circle</mat-icon>
                    <mat-icon *ngIf="element.listeeRecID && element.listeeStatus == 'N'" class="text-label-dimgrey" matTooltip="In Active Listee">cancel</mat-icon>
                    <mat-icon *ngIf="element.listeeRecID && element.listeeStatus == 'S'" class="text-label-blue" matTooltip="Suspended Listee">cancel</mat-icon>
                    <span *ngIf="element.listeeRecID" matTooltip="Listee Type">&nbsp;{{ getListeeAssoType(element.listeeType) }}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="mfrDetail">
            <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">MFR Detail</th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer" (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <span *ngIf="!element.mfr_AssoRecID">No MFR</span>
                    <mat-icon *ngIf="element.mfr_AssoRecID && element.mfr_Status == 'A'" class="text-label-green" matTooltip="Active MFR">check_circle</mat-icon>
                    <mat-icon *ngIf="element.mfr_AssoRecID && element.mfr_Status == 'W'" class="text-label-dimgrey" matTooltip="In Active MFR">cancel</mat-icon>
                    <mat-icon *ngIf="element.mfr_AssoRecID && element.mfr_Status == 'S'" class="text-label-blue" matTooltip="Suspended MFR">cancel</mat-icon>
                    <span *ngIf="element.mfr_AssoRecID" matTooltip="Mfr Type">&nbsp;{{ getMfrAssoType(element.mfr_Type) }}</span>
                </div>
            </td>
        </ng-container>
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header">Options</th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Options"><mat-icon>more_vert</mat-icon></button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="generateAuthPage(element)" [disabled]="!isAuthPageEligible(element)">
                        <mat-icon>assignment</mat-icon><span>Generate Auth.Page</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <mat-divider *ngIf="element == expandedElement"></mat-divider>
                <div class="table-element-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="ml-3 pl-4">
                        <div class="flex flex-row result-details-body flex-wrap items-center my-1 ml-2 px-2">
                            <ng-container *ngIf="element.fileType">
                                <span class="font-bold">File Type :&nbsp;</span>
                                <span>{{ element.fileType }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.partyNumber">
                                <span class="field-divider mx-1">&nbsp;</span>
                                <span class="font-bold">Party No :&nbsp;</span>
                                <span>{{ element.partyNumber }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownCompName">
                                <span class="field-divider mx-1">&nbsp;</span>
                                <span class="font-bold">Owner Comp.Name :&nbsp;</span>
                                <span>{{ element.ownCompName }}</span>
                            </ng-container>
                        </div>
                        <mat-divider *ngIf="element.mfr_AssoRecID || element.listeeRecID"></mat-divider>
                        <div class="flex flex-row result-details-body flex-wrap items-center my-1 ml-2 px-2">
                            <ng-container *ngIf="element.mfr_AssoRecID">
                                <span class="font-bold">MFR Comp.Name :&nbsp;</span>
                                <span>{{ element.mfr_CompName }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.listeeRecID">
                                <span class="field-divider mx-1" *ngIf="element.mfr_AssoRecID">&nbsp;</span>
                                <span class="font-bold">Listee Comp.Name :&nbsp;</span>
                                <span>{{ element.listeeCompName }}</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
            class="table-element-row" [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>