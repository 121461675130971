import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { CustomerDetailsModel, CustomerPartySiteModel } from '../../shared/customer-details/customerdetails.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';

import { Observable, of } from 'rxjs';
import { Constants } from '../../constants';
import { ListeeService } from '../listee.service';
import { MatCheckbox } from '@angular/material/checkbox';
import { DataService } from '../../core/services/data.service';
import { SidenavConfig } from '../../shared/side-nav/sidenav-config';
import { ActionType, CreateListeeModel } from './create-listee.model';
import { FileVolumeService } from '../../file-volume/file-volume.service';
import { catchError, debounceTime, map, switchMap, take } from 'rxjs/operators';
import { AbstractControl, AsyncValidatorFn, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ListeeTypeSelect, ListeeTypeDetails, ListeeTypeDetailsResultModel } from '../listee.model';
import { CustomerDetailsService } from '../../shared/customer-details/customerdetails.service';
import { SideNavLoaderComponent } from '../../shared/side-nav-loader/side-nav-loader.component';

@Component({
    selector: 'app-create-listee',
    templateUrl: './create-listee.component.html',
    styleUrls: ['./create-listee.component.scss']
})
export class CreateListeeComponent implements OnInit {
    @ViewChild('CreateNewListee', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild('sameAsApplicant') sameAsApplicantCheckBox: MatCheckbox;
    @ViewChild('submitButton') submitButton: ElementRef;

    listeeEndDate = null;
    listeeStartDate = null;
    actionType = ActionType;
    action = this.actionType.create;
    oldListeeAssoType: string = '';
    accNoVerification = false;
    models: CreateListeeModel[];

    isSubmitDisabled = false;
    isCheckboxDisabled = false;
    createEditListeeForm: FormGroup;
    sameAsApplicantCheckBoxValue = false;
    listeeTypeDetails: ListeeTypeDetails[] = [];
    listeeTypeOptions: ListeeTypeSelect[] = [];
    listeeAssociationType = Constants.ADD_LISTEE_ASSOCIATION_TYPE;
    customerPartySiteDetails: CustomerPartySiteModel[] = [];
    selectedcustomerPartySite: CustomerPartySiteModel;

    ownerAccountDetails: CustomerDetailsModel = {
        partyNumber: null,
        accountNumber: null,
        ownerCompanyName: null,
        customerPartySiteDetails: this.customerPartySiteDetails
    };
    model: CreateListeeModel = {
        fileVolRecId: 0,
        listeeRecId: 0,
        listeeFileNumber: '',
        accountNumber: '',
        orgNumber: '',
        isActive: '',
        listeePartySiteNumber: '',
        listeePartyNumber: '',
        subscriberNumber: '',
        companyName: '',
        companyAddress: '',
        associationType: '',
        startDate: '',
        endDate: '',
        lastUpdatedBy: '',
        listeeAddress1: '',
        listeeAddress2: '',
        listeeAddress3: '',
        listeeAddress4: '',
        listeeAddress5: '',
        listeeAddress6: '',
        listeeCity: '',
        listeeProvince: '',
        listeeState: '',
        listeeCountry: '',
        listeePostalCode: '',
        isAddToAllVolume: false
    };

    rawData; // To save a snippet of the data to get address column wise
    factory; componentRef; // Global variables for the creating and destroying the loading spinner
    isAccountNumberChanged = false; // To keep track if the account number is changed during edit action

    constructor(
        public sidenavConfig: SidenavConfig,
        private listeeService: ListeeService,
        private dataService: DataService,
        private toastService: ToastNotificationService,
        private customerDetailsService: CustomerDetailsService,
        private fileVolumeService: FileVolumeService,
        private resolver: ComponentFactoryResolver
    ) { }

    ngOnInit(): void {
        this.models = [];
        this.factory = this.resolver.resolveComponentFactory(SideNavLoaderComponent);

        this.createEditListeeForm = new FormGroup({
            fileVolRecId: new FormControl(null),
            listeeRecId: new FormControl(null),
            accountNumber: new FormControl(
                { value: null, disabled: false },
                {
                    validators: [Validators.required],
                    asyncValidators: [this.ownerAccountValidator()],
                    updateOn: 'blur'
                }
            ),
            orgNumber: new FormControl({ value: null, disabled: true }),
            listeePartySiteNumber: new FormControl(null),
            subscriberNumber: new FormControl({ value: null, disabled: true }),
            companyName: new FormControl({ value: null, disabled: true }),
            companyAddress: new FormControl({ value: null, disabled: true }),
            listeeFileNumber: new FormControl(null),
            associationType: new FormControl(null),
            startDate: new FormControl({ value: this.dataService.getCurrentDate() }),
            endDate: new FormControl({ value: null, disabled: true }),
            isActive: new FormControl(null),
            lastUpdatedBy: new FormControl(null),
            listeePartyNumber: new FormControl(null),
            isAddToAllVolume: new FormControl({ value: false })
        });

        /*this.model = Object.assign({}, JSON.parse(JSON.stringify(this.sidenavConfig.data.createListeeModel))); 
        this.models.push(this.model);*/

        this.listeeStartDate = new Date(this.sidenavConfig.data.createListeeModel.startDate);
        this.listeeEndDate = this.model.endDate ? new Date(this.sidenavConfig.data.createListeeModel.endDate) : null;
        if (this.sidenavConfig.data.action === 'create') {
            this.action = this.actionType.create;
            this.checkIfListeeExist();
        } else if (this.sidenavConfig.data.action === 'edit') {
            this.action = this.actionType.edit;
            this.setFormValues(this.sidenavConfig.data.createListeeModel);
        }
    }

    checkIfListeeExist() {
        this.componentRef = this.entry.createComponent(this.factory);
        const fileVolRecId = this.sidenavConfig.data.createListeeModel.fileVolRecId;
        this.listeeService.checkIfListeeExist(fileVolRecId).subscribe((result) => {
            this.componentRef.destroy();
            if (result) {
                this.disableAllFields();
                this.dataService.navigateToInnerSideNavTop();
                const alertMessage = `Listee already exist for the chosen file/volume combination`;
                const type = ToastNotificationType.infoBlue;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(alertMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            }
            else { this.setFormValues(this.sidenavConfig.data.createListeeModel); }

        }, error => {
            this.dataService.navigateToInnerSideNavTop();
            const errorMessage = `Error while trying to validate if listee already exist`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.componentRef.destroy();
        });
    }

    disableAllFields() {
        this.createEditListeeForm.get('accountNumber').disable();
        this.createEditListeeForm.get('listeePartySiteNumber').disable();
        this.createEditListeeForm.get('listeeFileNumber').disable();
        this.createEditListeeForm.get('associationType').disable();
        this.createEditListeeForm.get('startDate').disable();
        this.isSubmitDisabled = true;
        this.isCheckboxDisabled = true;
    }

    setFormValues(data: CreateListeeModel) {
        this.getFileListeeTypeDetails();
        const isEdit = this.sidenavConfig.data.action === 'edit';
        if (this.action == this.actionType.create) {
            this.createEditListeeForm.patchValue({
                fileVolRecId: data.fileVolRecId,
                listeeRecId: data.listeeRecId,
                associationType: null,
                startDate: new Date(data.startDate),
                endDate: data.endDate ? new Date(data.endDate) : null,
                isActive: data.isActive,
                lastUpdatedBy: data.lastUpdatedBy,
                isAddToAllVolume: false
            });
        }
        else {
            this.oldListeeAssoType = data.associationType;
            this.createEditListeeForm.patchValue({
                fileVolRecId: data.fileVolRecId,
                listeeRecId: data.listeeRecId,
                accountNumber: isEdit ? data.accountNumber : '',
                orgNumber: data.orgNumber ? data.orgNumber : null,
                listeePartySiteNumber: isEdit ? data.listeePartySiteNumber : '',
                listeePartyNumber: data.listeePartyNumber,
                subscriberNumber: '',
                companyName: '',
                companyAddress: '',
                listeeFileNumber: isEdit ? data.listeeFileNumber : '',
                associationType: data.associationType,
                startDate: new Date(data.startDate),
                endDate: data.endDate ? new Date(data.endDate) : null,
                isActive: data.isActive,
                lastUpdatedBy: data.lastUpdatedBy,
                isAddToAllVolume: false
            });
        }

        // A temp solution to fix the listee edit issue
        // Async validator not called on form value set
        setTimeout(() => {
            const control = this.createEditListeeForm.get("accountNumber"); // control which has async validator
            if (isEdit) { this.accNoVerification = true; }
            if (control.value) { control.setValue(control.value); }
        }, 100);
    }

    /**
     * This function will set the account number and the partysite number of the applicant file data.
     * Post that will set all the address details and other details related to account @param value
     **/
    setAccountDetails(event) {
        let isExists = false;
        let selectVal = 'S';
        let selectText = 'Same As Applicant';

        this.listeeTypeDetails.forEach((item, index) => {
            if (item.listeeType) {
                if (item.listeeType == 'R' && selectVal == 'S') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
                if (item.listeeType == 'S' && selectVal == 'R') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
            }
        });

        if (isExists) {
            event.source.checked = false;
            this.dataService.navigateToInnerSideNavTop();
            const alertMessage = `Cannot select '${selectText}'`;
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(alertMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.createEditListeeForm.patchValue({ associationType: null });
        }
        else {
            this.sameAsApplicantCheckBoxValue = event.checked;
            if (event.checked) {
                const data = this.sidenavConfig.data.createListeeModel;
                this.createEditListeeForm.patchValue({
                    listeeFileNumber: data.listeeFileNumber,
                    accountNumber: data.accountNumber,
                    associationType: 'S'
                });
            }
        }
    }

    changePartySiteNumber(e) {
        const partySiteNumber = this.createEditListeeForm.controls['listeePartySiteNumber'].value;
        this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
            (f) => f.partySiteNumber === Number(partySiteNumber) //Number(e.target.value)
        );
        this.getCompanyAddress(); // call API for getting the customer address and set subscriber here.
    }

    getCompanyAddress() {
        this.createEditListeeForm.get('companyName').setValue(this.ownerAccountDetails.ownerCompanyName);
        this.createEditListeeForm.get('subscriberNumber').setValue(this.selectedcustomerPartySite.subscriberNumber);
        this.rawData = this.selectedcustomerPartySite; // To get the address based on the individual fields while saving

        let customerAddress = '';
        if (this.selectedcustomerPartySite.address1) { customerAddress = `${this.selectedcustomerPartySite.address1}`; }
        if (this.selectedcustomerPartySite.address2) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address2}`; }
        if (this.selectedcustomerPartySite.address3) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address3}`; }
        if (this.selectedcustomerPartySite.address4) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.address4}`; }
        if (this.selectedcustomerPartySite.city) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.city}`; }
        if (this.selectedcustomerPartySite.province) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.province}`; }
        if (this.selectedcustomerPartySite.state) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.state}`; }
        if (this.selectedcustomerPartySite.county) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.county}`; }
        if (this.selectedcustomerPartySite.country) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.country}`; }
        if (this.selectedcustomerPartySite.postalCode) { customerAddress = `${customerAddress}\n${this.selectedcustomerPartySite.postalCode}`; }
        this.createEditListeeForm.get('companyAddress').setValue(customerAddress);
    }

    getPartySiteDetails(accountNumber: string, partySiteNumber: string) {
        this.componentRef = this.entry.createComponent(this.factory);
        this.customerDetailsService.getOwnerAccountDetails(accountNumber).subscribe(result => {
            if (result && result.length > 0) {
                this.ownerAccountDetails = result[0];
                this.createEditListeeForm.controls['accountNumber'].setErrors(null);
                this.createEditListeeForm.get('orgNumber').setValue(result[0].partyNumber);
                this.createEditListeeForm.controls['listeePartySiteNumber'].setValue(Number(partySiteNumber));
                this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
                    (f) => f.partySiteNumber === Number(partySiteNumber)
                );
                this.getCompanyAddress(); // call API for getting the customer address and set subscriber here.
                return null;
            } else {
                this.componentRef.destroy();
                return { invalidOwnerAcount: true };
            }
        }, (error) => {
            this.componentRef.destroy();
            of({ invalidOwnerAcount: true })
        });
    }

    getFileListeeTypeDetails() {
        const fileVolRecId = this.sidenavConfig.data.createListeeModel.fileVolRecId;
        this.listeeService.getFileListeeTypeDetails(fileVolRecId).subscribe((searchResult) => {
            this.listeeTypeDetails = searchResult.results;
            this.listeeAssociationType.forEach((item, index) => {
                let isExists = false;
                let selectVal = item.value;

                this.listeeTypeDetails.forEach((Data, idx) => {
                    if (Data.listeeType) {
                        if (Data.listeeType == 'R' && selectVal == 'S') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
                        else if (Data.listeeType == 'S' && selectVal == 'R') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
                    }
                });

                let tempRecord: ListeeTypeSelect = {
                    Option: item.key,
                    Value: item.value,
                    Disabled: isExists
                };
                this.listeeTypeOptions.push(tempRecord);
            });
        }, error => {
            this.listeeTypeDetails = [];
            this.listeeTypeOptions = [];
            this.dataService.navigateToInnerSideNavTop();
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const errMessage = `Error while trying to validate listee type`;
            const message = new ToastNotificationMessage(errMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        });
    }

    onListeeTypeChange(event) {
        let isExists = false;
        let selectVal = event.target.value;
        let selectText = event.target.options[event.target.options.selectedIndex].text;
        this.sameAsApplicantCheckBoxValue = false;

        this.listeeTypeDetails.forEach((item, index) => {
            if (item.listeeType) {
                if (item.listeeType == 'R' && selectVal == 'S') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
                if (item.listeeType == 'S' && selectVal == 'R') { isExists = (this.oldListeeAssoType == selectVal ? false : true); }
            }
        });

        if (isExists) {
            this.dataService.navigateToInnerSideNavTop();
            const type = ToastNotificationType.infoBlue;
            const alertMessage = `Cannot select '${selectText}'`;
            const placement = ToastNotificationPlacement.InnerSidenav;
            const message = new ToastNotificationMessage(alertMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.createEditListeeForm.patchValue({ associationType: null });
        }
        else if (selectVal == 'S' && this.action == this.actionType.create) {
            this.sameAsApplicantCheckBoxValue = true;
            const data = this.sidenavConfig.data.createListeeModel;
            this.createEditListeeForm.patchValue({
                listeeFileNumber: data.listeeFileNumber,
                accountNumber: data.accountNumber
            });
        }
    }

    resetCompanyDetails() {
        this.createEditListeeForm.patchValue({
            companyAddress: '',
            listeePartySiteNumber: null,
            orgNumber: '',
            subscriberNumber: '',
            companyName: ''
        });
        this.ownerAccountDetails.customerPartySiteDetails = [];
    }

    ownerAccountValidator(): AsyncValidatorFn {
        return (control: AbstractControl): | Promise<{ [key: string]: any } | null> | Observable<{ [key: string]: any } | null> =>
            control.valueChanges.pipe(debounceTime(500), take(1), switchMap((_) => {
                this.resetCompanyDetails();
                const data = this.sidenavConfig.data.createListeeModel;
                this.componentRef = this.entry.createComponent(this.factory);
                return this.customerDetailsService.getOwnerAccountDetails(control.value)
                    .pipe(map((result: CustomerDetailsModel[]) => {
                        if (result && result.length > 0) {
                            this.ownerAccountDetails = result[0];
                            this.createEditListeeForm.get('orgNumber').setValue(result[0].partyNumber);

                            if (this.sameAsApplicantCheckBoxValue || this.accNoVerification) {
                                this.createEditListeeForm.controls['listeePartySiteNumber'].setValue(Number(data.listeePartySiteNumber));
                                this.selectedcustomerPartySite = this.ownerAccountDetails.customerPartySiteDetails.find(
                                    (f) => f.partySiteNumber === Number(data.listeePartySiteNumber)
                                );
                                this.accNoVerification = false;
                                this.sameAsApplicantCheckBoxValue = false;
                                this.getCompanyAddress();
                            }

                            this.componentRef.destroy();
                            return null;
                        } else {
                            this.dataService.navigateToInnerSideNavTop();
                            this.sameAsApplicantCheckBoxValue = false;
                            this.createEditListeeForm.reset();
                            const type = ToastNotificationType.error;
                            const placement = ToastNotificationPlacement.InnerSidenav;
                            const errorMessage = `No Partysite number associated to given account number`;
                            const message = new ToastNotificationMessage(errorMessage, type, placement);
                            this.toastService.sendToastNotificationMessage(message);
                            this.componentRef.destroy();
                            return { invalidOwnerAcount: true };
                        }
                    }), catchError((error) => {
                        this.componentRef.destroy();
                        return of({ invalidOwnerAcount: true });
                    }));
            }));
    }

    onClose(): void {
        if (this.sidenavConfig.data.sideNav) {
            this.sidenavConfig.data.sideNav.close();
            this.listeeService.onRefreshSearchResult();
            if (this.action == this.actionType.create) {
                this.fileVolumeService.onRefreshSearchResult();
            }
        }
    }

    getDate = (date: string) => {
        return this.dataService.getDate(date);
    }

    setAddressFields() {
        this.model.listeeAddress1 = null;
        this.model.listeeAddress2 = null;
        this.model.listeeAddress3 = null;
        this.model.listeeAddress4 = null;
        this.model.listeeAddress5 = null;
        this.model.listeeAddress6 = null;
        this.model.listeePostalCode = null;
        this.model.listeeProvince = null;
        this.model.listeeCountry = null;
        this.model.listeeState = null;
        this.model.listeeCity = null;

        if (this.rawData) {
            if (this.rawData.address1) { this.model.listeeAddress1 = this.rawData.address1; }
            if (this.rawData.address2) { this.model.listeeAddress2 = this.rawData.address2; }
            if (this.rawData.address3) { this.model.listeeAddress3 = this.rawData.address3; }
            if (this.rawData.address4) { this.model.listeeAddress4 = this.rawData.address4; }
            if (this.rawData.address5) { this.model.listeeAddress5 = this.rawData.address5; }
            if (this.rawData.address6) { this.model.listeeAddress6 = this.rawData.address6; }
            if (this.rawData.postalCode) { this.model.listeePostalCode = this.rawData.postalCode; }
            if (this.rawData.province) { this.model.listeeProvince = this.rawData.province; }
            if (this.rawData.country) { this.model.listeeCountry = this.rawData.country; }
            if (this.rawData.state) { this.model.listeeState = this.rawData.state; }
            if (this.rawData.city) { this.model.listeeCity = this.rawData.city; }
        }
    }

    addToAllVolume(value) {
        this.model.isAddToAllVolume = value;
    }

    onSubmit(data) {
        // call the Add API here
        this.componentRef = this.entry.createComponent(this.factory);

        this.model.fileVolRecId = data.fileVolRecId;
        this.model.listeeRecId = data.listeeRecId ? data.listeeRecId : 0;
        this.model.listeeFileNumber = data.listeeFileNumber;
        this.model.accountNumber = this.createEditListeeForm.get('accountNumber').value ? this.createEditListeeForm.get('accountNumber').value : null;
        this.model.orgNumber = this.createEditListeeForm.get('orgNumber').value ? this.createEditListeeForm.get('orgNumber').value : null;
        this.model.isActive = data.isActive ? data.isActive : null;
        this.model.listeePartySiteNumber = data.listeePartySiteNumber ? data.listeePartySiteNumber : null;
        this.model.listeePartyNumber = this.createEditListeeForm.get('orgNumber').value ? this.createEditListeeForm.get('orgNumber').value : null;
        this.model.subscriberNumber = this.createEditListeeForm.get('subscriberNumber').value ? this.createEditListeeForm.get('subscriberNumber').value : null;
        this.model.companyName = this.createEditListeeForm.get('companyName').value ? this.createEditListeeForm.get('companyName').value : null;
        this.model.companyAddress = data.companyAddress;
        this.model.lastUpdatedBy = this.dataService.getLastUpdatedBy();
        this.model.associationType = data.associationType;
        this.model.endDate = null;

        if (data.startDate) { this.model.startDate = this.dataService.customDateFormat(this.getDate(data.startDate)); }
        else { this.model.startDate = this.dataService.customDateFormat(null); }
        if (this.listeeEndDate) { this.model.endDate = this.dataService.customDateFormat(this.getDate(this.listeeEndDate)); }
        this.setAddressFields(); // To set the address fields

        if (this.action == this.actionType.create) {
            this.listeeService.addListee(this.model).subscribe((result) => {
                if (result) {
                    this.models.push(this.sidenavConfig.data.createListeeModel);
                    this.model = this.models[this.models.length - 1];
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The Listee details have been added successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.sameAsApplicantCheckBox.checked = false;
                    this.createEditListeeForm.reset();
                    this.disableAllFields();
                    this.componentRef.destroy();
                }
            }, error => {
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to add the listee details`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.componentRef.destroy();
            });
        } else {
            this.listeeService.updateListeeDetail(this.model).subscribe((result) => {
                if (result) {
                    this.dataService.navigateToInnerSideNavTop();
                    const successMessage = `The Listee details have been updated successfully`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.InnerSidenav;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.componentRef.destroy();
                }
            }, error => {
                this.dataService.navigateToInnerSideNavTop();
                const errorMessage = `Error while trying to update the listee details`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.InnerSidenav;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
                this.componentRef.destroy();
            });
        }
    }
}