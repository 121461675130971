import { Component, OnInit, Input, Output, EventEmitter, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';

import { Subscription } from 'rxjs';
import { Constants } from '../../constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSort, Sort } from '@angular/material/sort';
import { state, style, trigger } from '@angular/animations';
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from '../../core/services/data.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { FileVolumeService } from '../../file-volume/file-volume.service';
import { AccLevelFileVolService } from '../account-file-vol-detail.service';
import { DataSearchRequest, DataResultSummary } from '../account-file-vol-detail.model';
import { GenerateAuthPageRecord, GenerateAuthPageModel } from '../../shared/utility.model';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { LoaderComponent } from '../../shared/loader/loader.component';

@Component({
    selector: 'app-account-file-vol-searchresult',
    templateUrl: './account-file-vol-searchresult.component.html',
    styleUrls: ['./account-file-vol-searchresult.component.scss'],
    animations: [trigger('detailExpand', [
        state('collapsed', style({ height: '0px', minHeight: '0' })),
        state('expanded', style({ height: '*' }))
    ])]
})

export class AccountFileVolSearchResultComponent implements OnInit {
    @ViewChild('accFileVolSearchResultContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public tableDataSource = new MatTableDataSource<DataResultSummary>([]);
    columnsToDisplay = Constants.CUST_ACC_FILE_VOL_RESULT_TABLE_COLUMNS;
    listeeType = Constants.LISTEE_ASSOCIATION_TYPE;
    pageSize = Constants.DEFAULT_PAGE_SIZE_50;
    expandedElement: DataResultSummary | null;
    @ViewChild(MatSort) sort: MatSort;
    mfrAssoType = Constants.MFR_TYPE;
    sortBy = Constants.SortBy;
    indexExpanded: number = -1;

    searchDataResults: DataResultSummary[] | null;
    searchRequest: DataSearchRequest = {
        pageSize: Constants.DEFAULT_PAGE_SIZE_50,
        filterBy: Constants.ACTIVE_SHORTCODE,
        sortDirection: "asc",
        sortBy: "fileNumber",
        partySiteNumber: '',
        accountNumber: '',
        exactSearch: true,
        pageNumber: 1
    };

    @Input() isTableView: boolean;
    @Input() set currentSort(value: Sort) {
        this.searchRequest.sortBy = value.active;
        this.searchRequest.sortDirection = value.direction;
    }
    @Input() set tableData(value: DataResultSummary[]) {
        if (this.isTableView) { this.tableDataSource = new MatTableDataSource(value); }
        else { this.searchDataResults = value; this.indexExpanded = -1; }
        this.checkIfSelected();
    }
    @Output() sortingChangeAction: EventEmitter<DataSearchRequest> = new EventEmitter();

    constructor(
        private modalService: NgbModal,
        private dataService: DataService,
        private volumeService: FileVolumeService,
        private resolver: ComponentFactoryResolver,
        private accFileVolService: AccLevelFileVolService,
        private toastService: ToastNotificationService) { }

    ngOnInit(): void { }

    sortData(sort: Sort) {
        const OrderByColumn = sort.active;
        if (this.searchRequest.sortBy === OrderByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else { this.searchRequest.sortDirection = this.sortBy.ASC; }

        this.searchRequest.sortBy = OrderByColumn;
        this.sortingChangeAction.emit(this.searchRequest);
    }

    getMfrAssoType(assoType: string): string | null {
        const item = this.mfrAssoType.find(item => item.value === assoType);
        return item ? item.key.toString() : null;
    }

    getListeeAssoType(assoType: string): string | null {
        const item = this.listeeType.find(item => item.value === assoType);
        return item ? item.key.toString() : null;
    }

    isAuthPageEligible(model: DataResultSummary): boolean {
        if (model.fileStatus != 'A') { return false; }
        else if (model.volumeStatus != 'A') { return false; }
        else if (model.actMfrInVolume == 'N') { return false; }
        else if (model.mfr_AssoRecID && model.mfr_Status != 'A') { return false; }
        else if (model.listeeRecID && model.listeeStatus != 'Y') { return false; }
        else if (model.volumeType?.toUpperCase() == 'ML VOLUME') { return false; }
        else if (model.fileType?.toUpperCase() == 'AGENCY') { return false; }
        else { return true; }
    }

    onAuthPageClick(event, fileNumber: string, volume: string, recId: number, modelData: DataResultSummary) {
        let tempArray = [];
        let tempRecord: GenerateAuthPageRecord = { fileNum: '', volume: '', fileRecId: '', fileVolRecId: '' };
        const currentRecord = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        if (event.checked) {
            if (modelData.fileType?.toUpperCase() == 'AGENCY') {
                const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                dialogRef.componentInstance.isCancelButtonRequired = false;
                dialogRef.componentInstance.data = {
                    confirmationInfo: 'Authpage cannot be generated for Agency file type volume(s)',
                    title: 'Alert Message', cancelButton: 'No', confirmButton: 'Ok'
                };
                dialogRef.result.then(result => {
                    event.source.checked = false;
                    modelData.isAuthPage = false;
                });
            }
            else {
                if (modelData?.actMfrInVolume == 'Y') {
                    tempRecord = { fileNum: fileNumber, volume: volume, fileRecId: '', fileVolRecId: '' };
                    if (currentRecord && currentRecord.length > 0) {
                        currentRecord.push(tempRecord);
                        this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, currentRecord);
                    }
                    else {
                        tempArray.push(tempRecord);
                        this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, tempArray);
                    }
                }
                else {
                    const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                    dialogRef.componentInstance.isCancelButtonRequired = false;
                    dialogRef.componentInstance.data = {
                        confirmationInfo: 'Invalid file volume record selected to generate Authpage. No Active MFR associated with the record',
                        title: 'Alert Message', cancelButton: 'No', confirmButton: 'Ok'
                    };
                    dialogRef.result.then(result => {
                        event.source.checked = false;
                        modelData.isAuthPage = false;
                    });
                }
            }
        }
        else {
            const index = currentRecord.findIndex(x => x.fileNum === fileNumber && x.volume === volume);
            if (index !== undefined) { currentRecord.splice(index, 1); }
            this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, currentRecord);
        }
    }

    checkIfSelected() {
        const existingRec = this.dataService.getOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID);
        if (!this.isTableView) {
            if (existingRec && existingRec.length > 0) {
                const length = existingRec.length;
                const modelLength = this.searchDataResults.length;
                for (let j = 0; j < length; j++) {
                    for (let i = 0; i < modelLength; i++) {
                        if (existingRec[j].fileNum == this.searchDataResults[i].fileNumber
                            && existingRec[j].volume == this.searchDataResults[i].fileVolume) {
                            this.searchDataResults[i].isAuthPage = true;
                            break;
                        }
                    }
                }
            }
        }
        else {
            if (existingRec && existingRec.length > 0) {
                const length = existingRec.length;
                const modelLength = this.tableDataSource.data.length;
                for (let j = 0; j < length; j++) {
                    for (let i = 0; i < modelLength; i++) {
                        if (existingRec[j].fileNum == this.tableDataSource.data[i].fileNumber
                            && existingRec[j].volume == this.tableDataSource.data[i].fileVolume) {
                            this.tableDataSource.data[i].isAuthPage = true;
                            break;
                        }
                    }
                }
            }
        }
    }

    generateAuthPage = (modelData) => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        if (modelData.fileType?.toUpperCase() == 'AGENCY') {
            const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
            dialogRef.componentInstance.isCancelButtonRequired = false;
            dialogRef.componentInstance.data = {
                confirmationInfo: 'Authpage cannot be generated for Agency file type volume(s)',
                title: 'Alert Message', cancelButton: 'No', confirmButton: 'Ok'
            };
            dialogRef.result.then(result => { componentRef.destroy(); });
        }
        else {
            if (modelData?.actMfrInVolume == 'Y') {
                let tempArray = [];
                let tempRecord: GenerateAuthPageRecord = {
                    fileNum: modelData.fileNumber,
                    fileVolRecId: modelData.fileVolRecID,
                    volume: modelData.fileVolume, fileRecId: ''
                };

                tempArray.push(tempRecord);
                const empId = this.dataService.getLastUpdatedBy();
                const request: GenerateAuthPageModel = { inputType: "FILE_VOL", empId: empId, records: tempArray };
                this.dataService.generateAuthPage(request).subscribe(result => {
                    componentRef.destroy();
                    if (result["OverallStatus"] == Constants.SUCCESS) {
                        const successMessage = `Successfully generated the Auth page for the file volume ${modelData.fileVolume}.`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.dataService.navigateToPageTop();
                        this.dataService.setOption(Constants.FILE_VOL_GEN_AUTH_PAGE_REC_ID, []);
                        this.volumeService.onRefreshSearchResult();
                    }
                    else {
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        var errorMessage = `Error while generating the Auth page for the file volume ${modelData.fileVolume}.`;
                        if (result["ErrorMessage"] != "") { errorMessage = result["ErrorMessage"]; };
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.dataService.navigateToPageTop();
                    }
                }, error => {
                    componentRef.destroy();
                    this.authpageError(modelData.fileVolume);
                });
            }
            else {
                const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                dialogRef.componentInstance.isCancelButtonRequired = false;
                dialogRef.componentInstance.data = {
                    title: 'Alert Message',
                    confirmationInfo: 'Invalid file volume record selected to generate Authpage. No Active MFR associated with the record',
                    cancelButton: 'No',
                    confirmButton: 'Ok'
                };
                dialogRef.result.then(result => { componentRef.destroy(); });
            }
        }
    }

    authpageError = (volume: string) => {
        const type = ToastNotificationType.error;
        const placement = ToastNotificationPlacement.Body;
        const errorMessage = `Error while generating the Auth page for the file volume ${volume}.`;
        const message = new ToastNotificationMessage(errorMessage, type, placement);
        this.toastService.sendToastNotificationMessage(message);
        this.dataService.navigateToPageTop();
    }
}