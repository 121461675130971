<ng-template #fileVolumeSearchContainer></ng-template>
<div *ngIf="!isTableView">
    <div *ngFor="let model of searchDataResults; let i = index;">
        <div class="search-result-card flex flex-grow py-2 mt-2"
             [ngClass]="{'card-active': model.fileVolumeStatus == 'A', 'card-inactive': model.fileVolumeStatus == 'W', 'card-suspend': model.fileVolumeStatus == 'S'}">
            <div class="flex flex-col flex-grow-0 justify-center authpage-separater">
                <div class="flex flex-row items-center px-2">
                    <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-1" name="isAuthPage"
                                  [disabled]="model.fileVolumeStatus == 'W' || model.fileVolumeStatus == 'S' || model.volumeType == 'ML VOLUME'"
                                  (change)="onAuthPageClick($event, model.fileNumber, model.volume, model.fileVolRecId, model)"
                                  [(ngModel)]="model.isAuthPage" id="{{'isAuthPage-' + model.fileVolRecId}}">
                    </mat-checkbox>
                </div>
            </div>
            <div class="flex flex-col flex-grow">
                <div class="flex flex-row items-center px-2">
                    <div class="flex my-2 flex-wrap">
                        <ng-container *ngIf="model.fileNumber">
                            <span class="font-bold">File:&nbsp;</span>
                            <span>{{ model.fileNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.fileCcn">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">CCN:&nbsp;</span>
                            <span>{{ model.fileCcn }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyName">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Company Name:&nbsp;</span>
                            <span>{{ model.ownerCompanyName }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.volume">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Vol:&nbsp;</span>
                            <span>{{ model.volume }}</span>
                        </ng-container>
                        <ng-container>
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Is Listee:&nbsp;</span>
                            <mat-icon *ngIf="model.isListee === 'Y'; else elseBlock" class="expand-arrow text-center text-label-green">
                                check_circle
                            </mat-icon>
                            <ng-template #elseBlock>
                                <mat-icon class="expand-arrow rotate-90deg text-center text-label-orange">
                                    cancel
                                </mat-icon>
                            </ng-template>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                    <ng-container>
                        <button (click)="indexExpanded = (indexExpanded == i?-1:i);" color="primary" class="text-black">
                            <fa-icon *ngIf="indexExpanded == i" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                            <fa-icon *ngIf="indexExpanded != i" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="model.ownerAccount">
                        <span class="font-bold">Account No:&nbsp;</span>
                        <span>{{ model.ownerAccount }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.partyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Org Number:&nbsp;</span>
                        <span>{{ model.partyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">PartySite No:&nbsp;</span>
                        <span>{{ model.ownerPartySiteNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.subscriberNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Subscriber Number:&nbsp;</span>
                        <span>{{ model.subscriberNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.responsibleOffice">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Responsible Office:&nbsp;</span>
                        <span>{{ model.responsibleOffice }}</span>
                    </ng-container>
                </div>
                <mat-divider *ngIf="indexExpanded == i"></mat-divider>
                <div>
                    <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                        <ng-container *ngIf="model.complementaryCCN">
                            <span class="font-bold">Comp CCN:&nbsp;</span>
                            <ng-container *ngFor="let compCCN of model.complementaryCCN;let indexOf=index;">
                                <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                                <span>{{ compCCN.compCCN }}</span>
                            </ng-container>
                            <span class="field-divider mx-2">&nbsp;</span>
                        </ng-container>
                        <ng-container *ngIf="model.markCode">
                            <span class="font-bold">Mark Code:&nbsp;</span>
                            <span>{{ model.markCode }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.wdrwlCode">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Withdrawal Code:&nbsp;</span>
                            <span>{{ model.wdrwlCode }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.volumeType">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Volume Type:&nbsp;</span>
                            <span>{{ model.volumeType }}</span>
                        </ng-container>
                    </div>
                    <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                        <ng-container *ngIf="model.startDate">
                            <span class="font-bold">Start Date:&nbsp;</span>
                            <span>{{ formatDate(model.startDate) }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.endDate">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">End Date:&nbsp;</span>
                            <span>{{ formatDate(model.endDate) }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.lastUpdatedBy">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Last Updated By:&nbsp;</span>
                            <span>{{ model.lastUpdatedBy }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCompanyAddress1">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Company Address:&nbsp;</span>
                        </ng-container>
                        <div class="flex flex-column file-details-body flex-wrap">
                            <ng-container *ngIf="model.ownerCompanyAddress1">
                                <div>{{ model.ownerCompanyAddress1 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCompanyAddress2">
                                <div>{{ model.ownerCompanyAddress2 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCompanyAddress3">
                                <div>{{ model.ownerCompanyAddress3 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCompanyAddress4">
                                <div>{{ model.ownerCompanyAddress4 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCompanyAddress5">
                                <div>{{ model.ownerCompanyAddress5 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCompanyAddress6">
                                <div>{{ model.ownerCompanyAddress6 }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCity">
                                <div>{{ model.ownerCity }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerProvince">
                                <div>{{ model.ownerProvince }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerState">
                                <div>{{ model.ownerState }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerCountry">
                                <div>{{ model.ownerCountry }}</div>
                            </ng-container>
                            <ng-container *ngIf="model.ownerPostalCode">
                                <div>{{ model.ownerPostalCode }}</div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex flex-col flex-grow-0 justify-start">
                <div class="flex flex-row items-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editVolume(model)" *ngIf="isAdmin || isEdit" [disabled]="model.fileVolumeStatus == 'S'">
                            <mat-icon>edit</mat-icon><span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="reactivateVolume(model, model.fileNumber, model.volume)" [disabled]="model.fileVolumeStatus == 'A'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': model.fileVolumeStatus == 'A' ? 'none' : 'block'}">
                            <mat-icon>replay</mat-icon><span>Re-Activate</span>
                        </button>
                        <button mat-menu-item (click)="withdrawVolume(model.fileVolRecId)" [disabled]="model.fileVolumeStatus == 'W' || model.fileVolumeStatus == 'S'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>archive</mat-icon><span>Withdraw</span>
                        </button>
                        <button mat-menu-item (click)="suspendVolume(model.fileVolRecId, model.fileNumber, model.volume)" [disabled]="model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>report_off</mat-icon><span>Suspend</span>
                        </button>
                        <button mat-menu-item (click)="viewMfr(model)">
                            <mat-icon>visibility</mat-icon><span>View Manufacturer(s)</span>
                        </button>
                        <button mat-menu-item (click)="createMfr(model)" [disabled]="model.fileVolumeStatus == 'W' || model.fileVolumeStatus == 'S'" 
                                [ngStyle]="{'display': (model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>add_circle_outline</mat-icon><span>Add Manufacturer(s)</span>
                        </button>
                        <button mat-menu-item (click)="viewListee(model)">
                            <mat-icon>visibility</mat-icon><span>View Listee</span>
                        </button>
                        <button mat-menu-item (click)="addListee(model)" [disabled]="model.fileVolumeStatus == 'W' || model.fileVolumeStatus == 'S'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                            <mat-icon>add_circle_outline</mat-icon><span>Add Listee</span>
                        </button>
                        <button mat-menu-item (click)="deleteVolume(model.fileVolRecId)" [disabled]="model.fileVolumeStatus == 'S'" 
                                *ngIf="isAdmin" [ngStyle]="{'display': (model.fileVolumeStatus == 'S') ? 'none' : 'block'}">
                            <mat-icon>delete_forever</mat-icon><span>Delete</span>
                        </button>
                        <button mat-menu-item (click)="generateAuthPage(model)"
                                [ngStyle]="{'display': (model.fileVolumeStatus == 'S' || model.fileVolumeStatus == 'W' || model.volumeType == 'ML VOLUME') ? 'none' : 'block'}"
                                [disabled]="model.fileVolumeStatus == 'W' || model.fileVolumeStatus == 'S' || model.volumeType == 'ML VOLUME'">
                            <mat-icon>assignment</mat-icon><span>Generate Auth Page</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>
    <ng-template #fileVolumeContainer></ng-template>
    <div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
        <table mat-table
               [dataSource]="tableDataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
               [matSortDisableClear]="true" [matSortActive]="searchRequest.sortBy" [matSortDirection]="searchRequest.sortDirection">
                <ng-container matColumnDef="authPage">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Auth Page </th>
                    <td mat-cell *matCellDef="let element">
                        <mat-checkbox class="ng-pristine ng-untouched ng-valid ng-empty mt-2" name="isAuthPage"
                                      [disabled]="element.fileVolumeStatus == 'W' || element.fileVolumeStatus == 'S' || element.volumeType == 'ML VOLUME'"
                                      (change)="onAuthPageClick($event, element.fileNumber, element.volume, element.fileVolRecId, element)"
                                      [(ngModel)]="element.isAuthPage" id="{{'isAuthPage-' + element.fileVolRecId}}">
                        </mat-checkbox>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fileVolumeStatus">
                    <th mat-header-cell *matHeaderCellDef class="table-header cell-left-align">Status</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        <div class="flex flex-wrap items-center">
                            <mat-icon *ngIf="element.fileVolumeStatus == 'A'" class="text-label-green font-bold">stop</mat-icon>
                            <mat-icon *ngIf="element.fileVolumeStatus == 'W'" class="text-label-dimgrey font-bold">stop</mat-icon>
                            <mat-icon *ngIf="element.fileVolumeStatus == 'S'" class="text-label-blue font-bold">stop</mat-icon>
                            <span *ngIf="element.fileVolumeStatus == 'A'"> Active</span>
                            <span *ngIf="element.fileVolumeStatus == 'W'"> Withdrawn</span>
                            <span *ngIf="element.fileVolumeStatus == 'S'"> Suspended</span>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="fileNumber">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>File Number</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.fileNumber}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="fileCcn">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>CCN</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.fileCcn}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="volume">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Volume</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.volume}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ownerCompanyName">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Company Name</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.ownerCompanyName}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ownerAccount">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Account No</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.ownerAccount}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="ownerPartySiteNumber">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>PartySite No</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.ownerPartySiteNumber}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="subscriberNumber">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Subscriber Number</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.subscriberNumber}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="isListee">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Is Listee</th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        <mat-icon *ngIf="element.isListee === 'Y'; else elseBlock" class="expand-arrow text-center text-label-green">
                            check_circle
                        </mat-icon>
                        <ng-template #elseBlock>
                            <mat-icon class="expand-arrow rotate-90deg text-center text-label-orange">
                                cancel
                            </mat-icon>
                        </ng-template>
                    </td>
                </ng-container>

                <ng-container matColumnDef="responsibleOffice">
                    <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header> Responsible Office </th>
                    <td mat-cell *matCellDef="let element" class="cursor-pointer cell-left-align"
                        (click)="expandedElement = expandedElement === element ? null : element">
                        {{element.responsibleOffice}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="options">
                    <th mat-header-cell *matHeaderCellDef class="table-header"> Options </th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                            <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item class=" additional-menu-item" (click)="editVolume(element)"
                                    *ngIf="isAdmin || isEdit" [disabled]="element.fileVolumeStatus == 'S'">
                                <mat-icon>edit</mat-icon><span>Edit</span>
                            </button>
                            <button mat-menu-item (click)="reactivateVolume(element, element.fileNumber, element.volume)" [disabled]="element.fileVolumeStatus == 'A'"
                                    *ngIf="isAdmin || isEdit" [ngStyle]="{'display': element.fileVolumeStatus == 'A' ? 'none' : 'block'}">
                                <mat-icon>replay</mat-icon><span>Re-Activate</span>
                            </button>
                            <button mat-menu-item (click)="withdrawVolume(element.fileVolRecId)" [disabled]="element.fileVolumeStatus == 'W' || element.fileVolumeStatus == 'S'"
                                    *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                                <mat-icon>archive</mat-icon><span>Withdraw</span>
                            </button>
                            <button mat-menu-item (click)="suspendVolume(element.fileVolRecId, element.fileNumber, element.volume)" [disabled]="element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W'"
                                    *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                                <mat-icon>report_off</mat-icon><span>Suspend</span>
                            </button>
                            <button mat-menu-item (click)="viewMfr(element)">
                                <mat-icon>visibility</mat-icon><span>View Manufacturers</span>
                            </button>
                            <button mat-menu-item (click)="createMfr(element)" [disabled]="element.fileVolumeStatus == 'W' || element.fileVolumeStatus == 'S'"
                                    [ngStyle]="{'display': (element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                                <mat-icon>add_circle_outline</mat-icon><span>Add Manufacturer(s)</span>
                            </button>
                            <button mat-menu-item (click)="viewListee(element)">
                                <mat-icon>visibility</mat-icon><span>View Listee</span>
                            </button>
                            <button mat-menu-item (click)="addListee(element)" [disabled]="element.fileVolumeStatus == 'W' || element.fileVolumeStatus == 'S'"
                                    *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W') ? 'none' : 'block'}">
                                <mat-icon>add_circle_outline</mat-icon><span>Add Listee</span>
                            </button>
                            <button mat-menu-item (click)="deleteVolume(element.fileVolRecId)" [disabled]="element.fileVolumeStatus == 'S'"
                                    *ngIf="isAdmin" [ngStyle]="{'display': (element.fileVolumeStatus == 'S') ? 'none' : 'block'}">
                                <mat-icon>delete_forever</mat-icon><span>Delete</span>
                            </button>
                            <button mat-menu-item (click)="generateAuthPage(element)"
                                    [ngStyle]="{'display': (element.fileVolumeStatus == 'S' || element.fileVolumeStatus == 'W' || element.volumeType == 'ML VOLUME') ? 'none' : 'block'}"
                                    [disabled]="element.fileVolumeStatus == 'W' || element.fileVolumeStatus == 'S' || element.volumeType == 'ML VOLUME'">
                                <mat-icon>assignment</mat-icon><span>Generate Auth Page</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>

                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                        <div class="table-element-detail"
                             [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="pl-4">
                                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                                    <ng-container *ngIf="element.partyNumber">
                                        <span class="font-bold">Org Number:&nbsp;</span>
                                        <span>{{ element.partyNumber }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="element.markCode">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Mark Code:&nbsp;</span>
                                        <span>{{ element.markCode }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="element.complementaryCCN">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Comp CCN:&nbsp;</span>
                                        <ng-container *ngFor="let compCCN of element.complementaryCCN;let indexOf=index;">
                                            <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                                            <span>{{ compCCN.compCCN }}</span>
                                        </ng-container>
                                    </ng-container>
                                    <ng-container *ngIf="element.wdrwlCode">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Withdrawal Code:&nbsp;</span>
                                        <span>{{ element.wdrwlCode }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="element.volumeType">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Volume Type:&nbsp;</span>
                                        <span>{{ element.volumeType }}</span>
                                    </ng-container>
                                </div>
                                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                                    <ng-container *ngIf="element.startDate">
                                        <span class="font-bold">Start Date:&nbsp;</span>
                                        <span>{{ formatDate(element.startDate) }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="element.endDate">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">End Date:&nbsp;</span>
                                        <span>{{ formatDate(element.endDate) }}</span>
                                    </ng-container>
                                    <ng-container *ngIf="element.lastUpdatedBy">
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Last Updated By:&nbsp;</span>
                                        <span>{{ element.lastUpdatedBy }}</span>
                                    </ng-container>
                                    <ng-container>
                                        <span class="field-divider mx-2">&nbsp;</span>
                                        <span class="font-bold">Company Address:&nbsp;</span>
                                    </ng-container>
                                    <div class="flex flex-column file-details-body flex-wrap">
                                        <ng-container *ngIf="element.ownerCompanyAddress1">
                                            <div>{{ element.ownerCompanyAddress1 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCompanyAddress2">
                                            <div>{{ element.ownerCompanyAddress2 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCompanyAddress3">
                                            <div>{{ element.ownerCompanyAddress3 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCompanyAddress4">
                                            <div>{{ element.ownerCompanyAddress4 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCompanyAddress5">
                                            <div>{{ element.ownerCompanyAddress5 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCompanyAddress6">
                                            <div>{{ element.ownerCompanyAddress6 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCity">
                                            <div>{{ element.ownerCity }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerProvince">
                                            <div>{{ element.ownerProvince }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerState">
                                            <div>{{ element.ownerState }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerCountry">
                                            <div>{{ element.ownerCountry }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="element.ownerPostalCode">
                                            <div>{{ element.ownerPostalCode }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
                    class="table-element-row"
                    [class.table-expanded-row]="expandedElement === element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
            </table>
    </div>
