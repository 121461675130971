import {
    Component, OnInit, Input, Output, EventEmitter,
    ViewContainerRef, ViewChild, ComponentFactoryResolver
} from '@angular/core';

import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { state, style, trigger } from '@angular/animations';
import { MatTableDataSource } from "@angular/material/table";
import { FileDetailsService } from '../file-details.service';
import { WithdrawOrDeleteFile } from '../withdraw-or-delete-file';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { FileSummary, WithdrawOrDeleteEventModel, FileSearchRequestModel } from '../file-detail-search.model';
import { CreateEditFileVolume } from '../../file-volume/create-file-volume/create-file-volume.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { WdrwlCodesSummary, GenerateAuthPageRecord } from '../../shared/utility.model';
import { ControlNumber, FileSuspendRequest } from '../file-detail-search.model';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { DataService } from '../../core/services/data.service';
import { CreateEditFile } from '../create-file-detail.model';
import { MatSort } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';

@Component({
    selector: 'app-file-search-result-card',
    templateUrl: './file-search-result-card.component.html',
    styleUrls: ['./file-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ])
    ]
})
export class FileSearchResultCardComponent implements OnInit {
    @ViewChild('fileCardContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild(MatSort) sort: MatSort;
    public tableDataSource = new MatTableDataSource<FileSummary>([]);
    columnsToDisplay = Constants.FILE_RESULT_TABLE_COLUMNS;
    expandedElement: FileSummary | null;
    sortBy = Constants.SortBy;
    indexExpanded: number = -1;
    isAdmin: boolean;
    isEdit: boolean;

    searchDataResults: FileSummary[] | null;
    searchRequest: FileSearchRequestModel = {
        fileNumber: '',
        ownerAccount: '',
        ccn: '',
        controlNumber: '',
        subscriberNumber: '',
        ownerCompanyName: '',
        partySiteNumber: '',
        orgNumber: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "asc",
        sortBy: "fileCcn",
        filterBy: Constants.ALL
    };

    @Input() isTableView: boolean;
    @Input() wdrwlCodes: WdrwlCodesSummary[];
    @Input() set currentSort(value: Sort) {
        this.searchRequest.sortBy = value.active;
        this.searchRequest.sortDirection = value.direction;
    }
    @Input() set tableData(value: FileSummary[]) {
        if (this.isTableView) { this.tableDataSource = new MatTableDataSource(value); }
        else { this.searchDataResults = value; this.indexExpanded = -1; }
        this.checkIfSelected();
    }
    @Output() withdrawOrDeleteAction: EventEmitter<WithdrawOrDeleteEventModel> = new EventEmitter();
    @Output() sortingChangeAction: EventEmitter<FileSearchRequestModel> = new EventEmitter();

    constructor(private router: Router,
        private dataService: DataService,
        private withdrawOrDelete: WithdrawOrDeleteFile,
        private modalService: NgbModal,
        private resolver: ComponentFactoryResolver,
        private fileService: FileDetailsService,
        private toastService: ToastNotificationService,
        private sideNavService: SideNavService) { }

    navigateToFileDetailPage(fileNumber: string, isEdit: boolean, fileDetails: FileSummary) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.dataService.setOption(Constants.FILE_VIEW_OPTIONS.fileNumber, fileNumber);
        this.dataService.setOption(Constants.FILE_VIEW_OPTIONS.isEdit, isEdit);
        this.dataService.setOption(Constants.WDRWL_CODES, this.wdrwlCodes);
        if (!isEdit) {
            this.dataService.setModelData(fileDetails);
            this.router.navigateByUrl(`/file-details/${fileDetails.fileRecId}/details`);
        } else {
            this.router.navigateByUrl(`/file-details/${fileDetails.fileRecId}/details`, { state: fileDetails });
        }
    }

    ngOnInit(): void {
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
        this.searchRequest = this.dataService.getOption(Constants.FILE_SEARCH_REQUEST_OBJECT);
    }

    ngAfterViewInit() { }

    sortData(sort: Sort) {
        const OrderByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === OrderByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else { this.searchRequest.sortDirection = this.sortBy.ASC; }

        this.searchRequest.sortBy = OrderByColumn;
        this.sortingChangeAction.emit(this.searchRequest);
    }

    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    /**
     * This will give a comma seperated string
     * of comp ccn for the particular manufacturer
     * @param compCcn
     */
    formatCompCCN = (compCcn) => {
        return this.dataService.formatCompCCN(compCcn);
    }

    getWidrawalCode(value): string {
        let code = "";
        this.wdrwlCodes.forEach((data) => {
            if (data.wdrwlRecId == value) { code = data.wdrwlCode; }
        });
        return code;
    }

    editFileDetails = (details: FileSummary) => {
        let controlNumber: ControlNumber[];
        if (details.controlNumbers) { controlNumber = details.controlNumbers; }
        else {
            controlNumber = [{
                controlNumberId: 0,
                controlNumber: '',
                fileRecId: null,
                action: Constants.ADD
            }];
        }
        const modelInfo: CreateEditFile = {
            fileRecId: details.fileRecId,
            fileType: details.fileType,
            ownerAccountNumber: details.ownerAccount,
            orgNumber: details.orgNumber,
            partySiteNumber: details.ownerPartySiteNumber,
            subscriberNumber: details.subscriberNumber,
            ownerCompanyName: details.ownerCompanyName,
            ownerCompanyAddress1: details.ownerCompanyAddress1,
            ownerCompanyAddress2: details.ownerCompanyAddress2,
            ownerCompanyAddress3: details.ownerCompanyAddress3,
            ownerCompanyAddress4: details.ownerCompanyAddress4,
            ownerCompanyAddress5: details.ownerCompanyAddress5,
            ownerCompanyAddress6: details.ownerCompanyAddress6,
            ownerPostalCode: details.ownerPostalCode,
            ownerCity: details.ownerCity,
            ownerState: details.ownerState,
            ownerCountry: details.ownerCountry,
            ownerProvince: details.ownerProvince,
            fileCcn: details.fileCcn,
            ownerFileNo: details.fileNumber,
            partyNumber: details.partyNumber,
            startDate: details.startDate,
            lastUpdatedBy: '',
            lastUpdatedOn: '',
            wdrwlStatus: 'A',
            wdrlRecId: 0,
            controlNumbers: controlNumber
        }
        this.sideNavService.createEditFile(modelInfo, 'edit');
    }

    withdrawFile(fileRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.withdrawFile(fileRecId, this.wdrwlCodes, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
        });
    }

    deleteFile(fileRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.deleteFile(fileRecId, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
        });
    }

    formatControlNumber = (controlNumber) => {
        if (controlNumber) {
            let temp = "";
            controlNumber.forEach(data => { temp += `${data.controlNumber},` });
            return temp.substring(0, temp.length - 1);
        }
        return "";
    }

    onAuthPageClick(event, fileNumber: string, modelData: FileSummary) {
        let tempArray = [];
        let tempRecord: GenerateAuthPageRecord = {
            fileNum: '',
            volume: '',
            fileRecId: '',
            fileVolRecId: ''
        };
        const currentRecord = this.dataService.getOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID);
        if (event.checked) {
            if (currentRecord && currentRecord.length > 0) {
                // Show to dialog box for error
                const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
                dialogRef.componentInstance.isCancelButtonRequired = false;
                dialogRef.componentInstance.data = {
                    title: 'Alert Message',
                    confirmationInfo: 'Please select only 1 file',
                    cancelButton: 'No',
                    confirmButton: 'Ok'
                };
                dialogRef.result
                    .then(result => {
                        event.source.checked = false;
                        modelData.isAuthPage = false;
                    });
            }
            else {
                tempRecord = {
                    fileNum: fileNumber,
                    volume: '',
                    fileRecId: '',
                    fileVolRecId: ''
                }
                tempArray.push(tempRecord);
                this.dataService.setOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID, tempArray);
            }
        }
        else {
            tempArray = [];
            this.dataService.setOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID, tempArray);
        }
    }

    checkIfSelected() {
        const existingRec = this.dataService.getOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID);
        if (!this.isTableView) {
            if (existingRec && existingRec.length > 0) {
                const modelLength = this.searchDataResults.length;
                for (let i = 0; i < modelLength; i++) {
                    if (existingRec[0].fileNum == this.searchDataResults[i].fileNumber) {
                        this.searchDataResults[i].isAuthPage = true;
                    }
                }
            }
        }
        else {
            if (existingRec && existingRec.length > 0) {
                const modelLength = this.tableDataSource.data.length;
                for (let i = 0; i < modelLength; i++) {
                    if (existingRec[0].fileNum == this.tableDataSource.data[i].fileNumber) {
                        this.tableDataSource.data[i].isAuthPage = true;
                    }
                }
            }
        }
    }

    /**
     * Function to reactivate the withdrawn file
     * @param fileRecId
     * @param fileNumber
     */
    reactivateFile(fileRecId: number, fileNumber: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Re-Activate File',
            confirmationInfo: 'Are you sure you want to re-activate the file?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                this.fileService.reactivateFile(fileRecId, this.dataService.getLastUpdatedBy()).subscribe(result => {
                    componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToPageTop();
                    if (result) {
                        const successMessage = `The ${fileNumber} file has been re-activated successfully.`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.fileService.onRefreshSearchResult();
                    }
                    else {
                        const errorMessage = `Error while trying to re-activate the ${fileNumber} file.`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                }, error => {
                    this.dataService.navigateToPageTop();
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    componentRef.destroy(); // To destroy the load spinner component
                    const errorMessage = `Error while trying to re-activate the ${fileNumber} file.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                });
            }).catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
    }

    /**
     * Function to add volume to a file
     * @param modelData
     */
    addVolume(modelData: FileSummary) {
        const compCCN = {
            compCCNRecId: 0,
            compCCN: "",
            fileVolRecId: 0,
            action: Constants.ADD
        };
        const modelInfo: CreateEditFileVolume = {
            volumeType: '',
            volume: '',
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            wdrwlCode: '',
            basicCcn: '',
            complementaryCCN: [compCCN],
            ownerFileNo: modelData.fileNumber,
            ownerAccount: modelData.ownerAccount,
            ownerPartySiteNumber: modelData.ownerPartySiteNumber,
            volumeStatus: 'A',
            serviceContratctNumber: null,
            isListee: null,
            fileRecId: modelData.fileRecId,
            wdrlRecId: 0,
            markCode: null,
            respOfcRecId: null,
            respOffice: null,
            isSetUpComplete: null,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate(),
        };
        const wdrwlCodes = this.dataService.getOption(Constants.WDRWL_CODES);
        this.sideNavService.addVolume(modelInfo, wdrwlCodes);
    }

    suspendFile(fileRecId: number, fileNumber: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: `Suspend File - ${fileNumber}`,
            confirmationInfo: `Are you sure you want to suspend the file?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                const lastUpdatedBy = this.dataService.getLastUpdatedBy();
                const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                const endDate = this.dataService.customDateFormat(null);
                const suspendRequest: FileSuspendRequest = {
                    fileRecId: fileRecId,
                    wdrlStatus: 'S',
                    fileVolStatus: 'S',
                    wdrlRecId: 0,
                    mfrStatus: 'S',
                    listeeIsActive: 'S',
                    lastUpdatedBy: lastUpdatedBy,
                    lastUpdatedOn: lastUpdatedDate,
                    endDate: endDate
                }

                this.fileService.suspendFile(suspendRequest).subscribe(result => {
                    const returnCode = !isNaN(Number(result)) ? Number(result) : 99;
                    componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToPageTop();
                    if (returnCode == 1) {
                        const successMessage = `The ${fileNumber} file has been suspended successfully.`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement, 10000);
                        this.toastService.sendToastNotificationMessage(message);
                        this.fileService.onRefreshSearchResult();
                    }
                    else {
                        const errorMessage = `Error while trying to suspend the ${fileNumber} file.`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                }, error => {
                    this.dataService.navigateToPageTop();
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    componentRef.destroy(); // To destroy the load spinner component
                    const errorMessage = `Error while trying to suspend the ${fileNumber} file.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                });
            }).catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
    }

    ecmCreateFolder(details: FileSummary) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: `Create File Folder ${details.fileNumber} - ECM`,
            confirmationInfo: 'Are you sure you want to create the folder in ECM?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };

        const modelInfo: CreateEditFile = {
            fileRecId: details.fileRecId,
            fileType: details.fileType,
            ownerAccountNumber: details.ownerAccount,
            orgNumber: details.orgNumber,
            partySiteNumber: details.ownerPartySiteNumber,
            subscriberNumber: details.subscriberNumber,
            ownerCompanyName: details.ownerCompanyName,
            ownerCompanyAddress1: details.ownerCompanyAddress1,
            ownerCompanyAddress2: details.ownerCompanyAddress2,
            ownerCompanyAddress3: details.ownerCompanyAddress3,
            ownerCompanyAddress4: details.ownerCompanyAddress4,
            ownerCompanyAddress5: details.ownerCompanyAddress5,
            ownerCompanyAddress6: details.ownerCompanyAddress6,
            ownerPostalCode: details.ownerPostalCode,
            ownerCity: details.ownerCity,
            ownerState: details.ownerState,
            ownerCountry: details.ownerCountry,
            ownerProvince: details.ownerProvince,
            fileCcn: details.fileCcn,
            ownerFileNo: details.fileNumber,
            partyNumber: details.partyNumber,
            startDate: details.startDate,
            lastUpdatedBy: '',
            lastUpdatedOn: '',
            wdrwlStatus: 'A',
            wdrlRecId: 0,
            controlNumbers: []
        }

        dialogRef.result.then(result => {
            this.fileService.ecmCreateFolder(modelInfo).subscribe(result => {
                if (result?.nodeId > 0) {
                    componentRef.destroy();
                    this.dataService.navigateToPageTop();
                    let type = ToastNotificationType.infoBlue;
                    var infoMessage = `Folder ${details.fileNumber} already available in ECM.`;
                    if (result?.status) {
                        type = ToastNotificationType.success;
                        infoMessage = `Folder ${details.fileNumber} has been created successfully in ECM.`;
                    }
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(infoMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
                else {
                    componentRef.destroy();
                    this.dataService.navigateToPageTop();
                    const errorMessage = `Error while trying to create folder in ECM. Please try again.`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 15000);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                componentRef.destroy();
                this.dataService.navigateToPageTop();
                const errorMessage = `Error while trying to create folder in ECM. Please try again.`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement, 15000);
                this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') { componentRef.destroy(); }
        });
    }
}