<ng-template #fileDetailViewContainer></ng-template>
<section>
    <div class="px-20">
        <app-breadcrumb [levelOneTitle]="pageName" [levelTwoTitle]="levelTwoTitle" [activeTitleLevel]="activeLevel">
        </app-breadcrumb>
        <div>
            <div class="font-bold inline-block">
                <div class="flex flex-wrap items-center">
                    {{modelData.fileNumber}} | {{ modelData.fileCcn }} |
                    <mat-icon *ngIf="modelData.fileStatus == 'A'" class="text-label-green">stop</mat-icon>
                    <mat-icon *ngIf="modelData.fileStatus == 'W'" class="text-label-dimgrey">stop</mat-icon>
                    <mat-icon *ngIf="modelData.fileStatus == 'S'" class="text-label-blue">stop</mat-icon>
                    <span *ngIf="modelData.fileStatus == 'A'"> Active</span>
                    <span *ngIf="modelData.fileStatus == 'W'"> Withdrawn</span>
                    <span *ngIf="modelData.fileStatus == 'S'"> Suspended</span>
                </div>
            </div>
            <div class="additional-menu float-right inline-block">
                <button [matMenuTriggerFor]="menuFile" class="additional-menu-button" aria-label="Menu">
                    <mat-icon class="text-3xl">menu</mat-icon>
                </button>
                <mat-menu #menuFile="matMenu">
                    <button mat-menu-item (click)="editFile(modelData)" *ngIf="isAdmin || isEdit"
                            [disabled]="modelData.fileStatus == 'W' || modelData.fileStatus == 'S'">
                        <mat-icon>edit</mat-icon><span>Edit File</span>
                    </button>
                    <button mat-menu-item (click)="addVolume(modelData)" *ngIf="isAdmin || isEdit"
                            [disabled]="modelData.fileStatus == 'W' || modelData.fileStatus == 'S'">
                        <mat-icon>add_circle_outline</mat-icon><span>Add Volume</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/file-details/',modelData.fileRecId,'file-volume']">
                        <mat-icon>visibility</mat-icon><span>View Volume(s)</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/file-details/',modelData.fileRecId,'mfr-details']">
                        <mat-icon>visibility</mat-icon><span>View Manufacturer(s)</span>
                    </button>
                    <button mat-menu-item [routerLink]="['/file-details/',modelData.fileRecId,'listee-details']">
                        <mat-icon>visibility</mat-icon><span>View Listee</span>
                    </button>
                    <button mat-menu-item (click)="reactivateFile(modelData.fileRecId, modelData.fileNumber)" [disabled]="modelData.fileStatus == 'A'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': modelData.fileStatus == 'A' ? 'none' : 'block'}">
                        <mat-icon>replay</mat-icon><span>Re-Activate File</span>
                    </button>
                    <button mat-menu-item (click)="withdrawFile(modelData.fileRecId)" [disabled]="modelData.fileStatus == 'S' || modelData.fileStatus == 'W'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (modelData.fileStatus == 'S' || modelData.fileStatus == 'W') ? 'none' : 'block'}">
                        <mat-icon>archive</mat-icon><span>Withdraw File</span>
                    </button>
                    <button mat-menu-item (click)="suspendFile(modelData.fileRecId, modelData.fileNumber)" [disabled]="modelData.fileStatus == 'S' || modelData.fileStatus == 'W'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': (modelData.fileStatus == 'S' || modelData.fileStatus == 'W') ? 'none' : 'block'}">
                        <mat-icon>report_off</mat-icon><span>Suspend File</span>
                    </button>
                    <button mat-menu-item (click)="deleteFile(modelData.fileRecId)" [disabled]="modelData.fileStatus == 'S'"
                            *ngIf="isAdmin" [ngStyle]="{'display': (modelData.fileStatus == 'S') ? 'none' : 'block'}">
                        <mat-icon>delete_forever</mat-icon><span>Delete File</span>
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <hr class="hr-line" />
    <div class="ml-16 w-11/12">
        <mat-tab-group dynamicHeight mat-align-tabs="start" animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
            <mat-tab label="Detail">
                <mat-card class="mt-2"
                          joyrideStep="step7"
                          title="View File Details"
                          text="This space will list all the details related to the chosen file number"
                          stepPosition="center">
                    <mat-card-content>
                        <form [formGroup]="editFileForm">
                            <div class="mb-2 row">
                                <div class="col-md-4">
                                    <label for="inputOwnerAccountNumber" class="form-label">Account Number</label>
                                    <label class="form-control-label">{{ modelData.ownerAccount}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputFileNumber" class="form-label">File Number</label>
                                    <label class="form-control-label">{{ modelData.fileNumber}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputOrgNumber" class="form-label">Org Number</label>
                                    <label class="form-control-label">{{ modelData.partyNumber}}</label>
                                </div>
                            </div>
                            <div class="mb-2 row">
                                <div class="col-md-4">
                                    <label for="inputFileStatus" class="form-label">Status</label>
                                    <label class="form-control-label" *ngIf="modelData.fileStatus == 'A'">Active</label>
                                    <label class="form-control-label" *ngIf="modelData.fileStatus == 'W'">Withdrawn</label>
                                    <label class="form-control-label" *ngIf="modelData.fileStatus == 'S'">Suspended</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputPartySiteNumber" class="form-label">Party Site Number</label>
                                    <label class="form-control-label">{{ modelData.ownerPartySiteNumber}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputFileType" class="form-label">File Type</label>
                                    <label class="form-control-label">{{ modelData.fileType}}</label>
                                </div>
                            </div>
                            <div class="mb-2 row">
                                <div class="col-md-4">
                                    <label for="inputSubscriberNumber" class="form-label">Subscriber Number</label>
                                    <label class="form-control-label">{{ modelData.subscriberNumber}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for=" inputCCN" class="form-label">CCN</label>
                                    <label class="form-control-label">{{ modelData.fileCcn}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputControlNumber" class="form-label">File Volume CCN List</label>
                                    <label class="form-control-label">{{ modelData.volCcnList }}</label>
                                </div>
                            </div>
                            <div class="mb-2 row">
                                <div class="col-md-4">
                                    <label for="inputControlNumber" class="form-label">Complementary CCN List</label>
                                    <label class="form-control-label">{{ formatCompCCN(modelData.complementaryCCN) }}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputControlNumber" class="form-label">Control Number</label>
                                    <label class="form-control-label">{{ formatControlNumber(modelData.controlNumbers) }}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputStartDate" class="form-label">Start Date</label>
                                    <label class="form-control-label">{{ formatDate(modelData.startDate) }}</label>
                                </div>
                            </div>
                            <div class="mb-2 row">
                                <div class="col-md-4">
                                    <label for="inputEndDate" class="form-label">End Date</label>
                                    <label class="form-control-label">{{ formatDate(modelData.endDate) }}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputWdrwlCode" class="form-label">Withdrawal Code / Description</label>
                                    <label class="form-control-label" *ngIf="modelData.wdrwlCode">{{ modelData.wdrwlCode +' - '+ modelData.wdrwlDescription }}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputOwnerCompanyName" class="form-label">Company Name</label>
                                    <label class="form-control-label">{{ modelData.ownerCompanyName}}</label>
                                </div>
                                <div class="col-md-4">
                                    <label for="inputOwnerCompanyAddress" class="form-label">Company Address</label>
                                    <div class="form-control-label">
                                        <ng-container *ngIf="modelData.ownerCompanyAddress1">
                                            <div>{{ modelData.ownerCompanyAddress1 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCompanyAddress2">
                                            <div>{{ modelData.ownerCompanyAddress2 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCompanyAddress3">
                                            <div>{{ modelData.ownerCompanyAddress3 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCompanyAddress4">
                                            <div>{{ modelData.ownerCompanyAddress4 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCompanyAddress5">
                                            <div>{{ modelData.ownerCompanyAddress5 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCompanyAddress6">
                                            <div>{{ modelData.ownerCompanyAddress6 }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCity">
                                            <div>{{ modelData.ownerCity }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerProvince">
                                            <div>{{ modelData.ownerProvince }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerState">
                                            <div>{{ modelData.ownerState }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerCountry">
                                            <div>{{ modelData.ownerCountry }}</div>
                                        </ng-container>
                                        <ng-container *ngIf="modelData.ownerPostalCode">
                                            <div>{{ modelData.ownerPostalCode }}</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </mat-card-content>
                </mat-card>
            </mat-tab>
            <mat-tab label="Delete History">
                <mat-card *ngIf="!deletedHistory">
                    <mat-card-content class="ml-20">
                        <div class="flex flex-col justify-center max-w-full mx-auto my-3">
                            <div class="flex">
                                <img src="../../../assets/images/noResults.png" class="Bitmap mx-auto" />
                            </div>
                            <div class="flex">
                                <div class="feedback-header mx-auto title-no-data">No delete details found</div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <mat-card *ngIf="dataFileSource?.data.length">
                    <mat-card-title>Delete : File</mat-card-title>
                    <mat-card-content><div class="mat-table-container">
                        <table mat-table [dataSource]="dataFileSource" class="TableDeleteDetail">
                            <ng-container matColumnDef="fileNumber">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Number </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileNumber }}</td>
                            </ng-container>
                            <ng-container matColumnDef="fileType">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Type </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileType }}</td>
                            </ng-container>
                            <ng-container matColumnDef="accountNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Account No </th>
                                <td mat-cell *matCellDef="let element">{{ element.accountNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partyNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partyNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partySiteNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party Site No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partySiteNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="companyName">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Company Name </th>
                                <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Start Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> End Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedOn">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted On </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedOn }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedBy">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted By </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedBy }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deleteLevel">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted Level </th>
                                <td mat-cell *matCellDef="let element">{{ element.deleteLevel }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColFileDetail; sticky: true"></tr>
                            <tr mat-row class="table-element-row" *matRowDef="let elementrow; columns: displayColFileDetail"></tr>
                        </table>
                    </div></mat-card-content>
                </mat-card>
                <mat-card *ngIf="dataFileVolumeSource?.data.length">
                    <mat-card-title>Delete : File Volume</mat-card-title>
                    <mat-card-content><div class="mat-table-container">
                        <table mat-table [dataSource]="dataFileVolumeSource" class="TableDeleteDetail">
                            <ng-container matColumnDef="fileNumber">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Number </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileNumber }}</td>
                            </ng-container>
                            <ng-container matColumnDef="fileVolume">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Volume </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileVolume }}</td>
                            </ng-container>
                            <ng-container matColumnDef="volumeType">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Volume Type </th>
                                <td mat-cell *matCellDef="let element">{{ element.volumeType }}</td>
                            </ng-container>
                            <ng-container matColumnDef="basic_CCN">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Basic CCN </th>
                                <td mat-cell *matCellDef="let element">{{ element.basic_CCN }}</td>
                            </ng-container>
                            <ng-container matColumnDef="accountNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Account No </th>
                                <td mat-cell *matCellDef="let element">{{ element.accountNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partyNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partyNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partySiteNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party Site No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partySiteNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="companyName">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Company Name </th>
                                <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Start Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> End Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedOn">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted On </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedOn }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedBy">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted By </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedBy }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deleteLevel">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted Level </th>
                                <td mat-cell *matCellDef="let element">{{ element.deleteLevel }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColFileVolume; sticky: true"></tr>
                            <tr mat-row class="table-element-row" *matRowDef="let elementrow; columns: displayColFileVolume"></tr>
                        </table>
                    </div></mat-card-content>
                </mat-card>
                <mat-card *ngIf="dataMfrAssociationSource?.data.length">
                    <mat-card-title>Delete : MFR Association</mat-card-title>
                    <mat-card-content><div class="mat-table-container">
                        <table mat-table [dataSource]="dataMfrAssociationSource" class="TableDeleteDetail">
                            <ng-container matColumnDef="fileNumber">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Number </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileNumber }}</td>
                            </ng-container>
                            <ng-container matColumnDef="fileVolume">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> File Volume </th>
                                <td mat-cell *matCellDef="let element">{{ element.fileVolume }}</td>
                            </ng-container>
                            <ng-container matColumnDef="accountNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Account No </th>
                                <td mat-cell *matCellDef="let element">{{ element.accountNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partyNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partyNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="partySiteNo">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Party Site No </th>
                                <td mat-cell *matCellDef="let element">{{ element.partySiteNo }}</td>
                            </ng-container>
                            <ng-container matColumnDef="mFR_Type">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> MFR Type </th>
                                <td mat-cell *matCellDef="let element">{{ element.mFR_Type }}</td>
                            </ng-container>
                            <ng-container matColumnDef="companyName">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Company Name </th>
                                <td mat-cell *matCellDef="let element">{{ element.companyName }}</td>
                            </ng-container>
                            <ng-container matColumnDef="startDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Start Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.startDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="endDate">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> End Date </th>
                                <td mat-cell *matCellDef="let element">{{ element.endDate }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedOn">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted On </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedOn }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deletedBy">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted By </th>
                                <td mat-cell *matCellDef="let element">{{ element.deletedBy }}</td>
                            </ng-container>
                            <ng-container matColumnDef="deleteLevel">
                                <th mat-header-cell *matHeaderCellDef class="table-header"> Deleted Level </th>
                                <td mat-cell *matCellDef="let element">{{ element.deleteLevel }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="displayColMfrDetail; sticky: true"></tr>
                            <tr mat-row class="table-element-row" *matRowDef="let elementrow; columns: displayColMfrDetail"></tr>
                        </table>
                    </div></mat-card-content>
                </mat-card>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>