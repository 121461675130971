<ng-template #mfrSearchContainer></ng-template>
<div *ngIf="!isTableView">
    <div *ngFor="let model of searchDataResults; let i = index;">
        <div class="search-result-card flex flex-grow py-2 mt-2"
             [ngClass]="{'card-active': model.mfrStatus == 'A', 'card-inactive': model.mfrStatus == 'W', 'card-suspend': model.mfrStatus == 'S'}">
            <div class="flex flex-col flex-grow">
                <div class="flex flex-row items-center px-2">
                    <div class="flex my-2 flex-wrap">
                        <ng-container *ngIf="model.fileNumber">
                            <span class="font-bold">File Number:&nbsp;</span>
                            <span>{{ model.fileNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.volume">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Volume:&nbsp;</span>
                            <span>{{ model.volume }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.mfrAccount">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">MFR Account No:&nbsp;</span>
                            <span>{{ model.mfrAccount }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.mfrPartyNumber">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">MFR Org No:&nbsp;</span>
                            <span>{{ model.mfrPartyNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.mfrPartySiteNumber">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">MFR Partysite No:&nbsp;</span>
                            <span>{{ model.mfrPartySiteNumber }}</span>
                        </ng-container>
                        <ng-container *ngIf="model.mfrSubscriberNumber">
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">MFR Subscriber No:&nbsp;</span>
                            <span>{{ model.mfrSubscriberNumber }}</span>
                        </ng-container>
                        <ng-container>
                            <span class="field-divider mx-2">&nbsp;</span>
                            <span class="font-bold">Is Listee:&nbsp;</span>
                            <mat-icon *ngIf="model.isListee === 'Y'; else elseBlock" class="expand-arrow text-center text-label-green">check_circle</mat-icon>
                            <ng-template #elseBlock><mat-icon class="expand-arrow rotate-90deg text-center text-label-orange">cancel</mat-icon></ng-template>
                        </ng-container>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row mt-2 file-details-body p-2 flex-wrap">
                    <ng-container *ngIf="model.ownerCompanyName">
                        <span class="font-bold">Applicant Company Name:&nbsp;</span>
                        <span>{{ model.ownerCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerAccount">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Account No:&nbsp;</span>
                        <span>{{model.ownerAccount}}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPartyNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Org No:&nbsp;</span>
                        <span>{{ model.ownerPartyNumber }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerPartySiteNumber">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant Partysite No:&nbsp;</span>
                        <span>{{model.ownerPartySiteNumber}}</span>
                    </ng-container>
                    <ng-container *ngIf="model.ownerCountryCode">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Applicant CountryCode:&nbsp;</span>
                        <span>{{model.ownerCountryCode}}</span>
                    </ng-container>
                </div>
                <mat-divider></mat-divider>
                <div class="flex flex-row mt-1 file-details-body p-2 flex-wrap">
                    <ng-container>
                        <button (click)="indexExpanded = (indexExpanded == i?-1:i);" color="primary" class="text-black">
                            <fa-icon *ngIf="indexExpanded == i" icon="caret-down" class="pr-2 text-xl"></fa-icon>
                            <fa-icon *ngIf="indexExpanded != i" icon="caret-right" class="pr-2 text-xl"></fa-icon>
                        </button>
                    </ng-container>
                    <ng-container *ngIf="model.mfrCompanyName">
                        <span class="font-bold">MFR Company Name:&nbsp;</span>
                        <span>{{ model.mfrCompanyName }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrType">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Type:&nbsp;</span>
                        <span>{{ model.mfrType }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.fileCcn">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">CCN:&nbsp;</span>
                        <span>{{ model.fileCcn }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.mfrFactoryId">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Factory ID:&nbsp;</span>
                        <span>{{model.mfrFactoryId}}</span>
                    </ng-container>
                    <ng-container *ngIf="model.complementaryCCN">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Comp CCN:&nbsp;</span>
                        <span>{{ formatCompCCN(model.complementaryCCN) }}</span>
                    </ng-container>
                </div>
                <mat-divider *ngIf="indexExpanded == i"></mat-divider>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                    <ng-container *ngIf="model.serviceContract">
                        <div class="font-bold">Service Contract#:&nbsp;</div>
                        <span>{{ model.serviceContract }}</span>
                        <span class="field-divider mx-2">&nbsp;</span>
                    </ng-container>
                    <ng-container *ngIf="model.startDate">
                        <span class="field-divider mx-2" *ngIf="model.serviceContract">&nbsp;</span>
                        <div class="font-bold">Start Date:&nbsp;</div>
                        <span>{{ formatDate(model.startDate) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.endDate">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <div class="font-bold">End Date:&nbsp;</div>
                        <span>{{ formatDate(model.endDate) }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.lastUpdatedBy">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Last Updated By:&nbsp;</span>
                        <span>{{ model.lastUpdatedBy }}</span>
                    </ng-container>
                    <ng-container *ngIf="model.wdrwlCode">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Withdrawl Code:&nbsp;</span>
                        <span>{{ model.wdrwlCode }}</span>
                    </ng-container>
                </div>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i && model.ownerAddress1">
                    <ng-container *ngIf="model.ownerAddress1">
                        <div class="font-bold">MFR Company Address:&nbsp;</div>
                    </ng-container>
                    <div class="flex flex-column file-details-body flex-wrap">
                        <ng-container *ngIf="model.ownerAddress1">
                            <div>{{ model.ownerAddress1 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerAddress2">
                            <div>{{ model.ownerAddress2 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerAddress3">
                            <div>{{ model.ownerAddress3 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerAddress4">
                            <div>{{ model.ownerAddress4 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerAddress5">
                            <div>{{ model.ownerAddress5 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerAddress6">
                            <div>{{ model.ownerAddress6 }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCity">
                            <div>{{ model.ownerCity }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerProvince">
                            <div>{{ model.ownerProvince }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerState">
                            <div>{{ model.ownerState }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerCountry">
                            <div>{{ model.ownerCountry }}</div>
                        </ng-container>
                        <ng-container *ngIf="model.ownerPostalCode">
                            <div>{{ model.ownerPostalCode }}</div>
                        </ng-container>
                    </div>
                </div>
                <div class="flex flex-row my-1 file-details-body p-2 flex-wrap" *ngIf="indexExpanded == i">
                    <ng-container>
                        <span class="font-bold">IC/LC Information:&nbsp;</span>
                        <span><a class="viewinfo" (click)="viewIcLcInfo(model.mfrPartySiteNumber)">View Info</a></span>
                    </ng-container>
                    <ng-container *ngIf="model.deliverableItemInstance">
                        <span class="field-divider mx-2">&nbsp;</span>
                        <span class="font-bold">Deliverable Item Instance Name:&nbsp;</span>
                        <ng-container *ngFor="let deliverableItemInstance of model.deliverableItemInstance;let indexOf=index;">
                            <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                            <span>{{ deliverableItemInstance.deliverableItemInstanceName}}</span>
                        </ng-container>
                    </ng-container>
                </div>
            </div>
            <div class="flex flex-col flex-grow-0 justify-start" *ngIf="isAdmin || isEdit">
                <div class="flex flex-row items-center">
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="editMfrDetail(model)" *ngIf="isAdmin || isEdit" [disabled]="model.mfrStatus == 'S'">
                            <mat-icon>edit</mat-icon><span>Edit</span>
                        </button>
                        <button mat-menu-item (click)="reactivateMfr(model)" [disabled]="model.mfrStatus == 'A'"
                                *ngIf="isAdmin || isEdit" [ngStyle]="{'display': model.mfrStatus == 'A' ? 'none' : 'block'}">
                            <mat-icon>replay</mat-icon><span>Re-Activate</span>
                        </button>
                        <button mat-menu-item (click)="withdrawMfr(model.mfrAssoRecId, model.fileNumber, model.mfrPartySiteNumber, model.volumeType)"
                                [ngStyle]="{'display': (model.mfrStatus == 'S' || model.mfrStatus == 'W') ? 'none' : 'block'}"
                                [disabled]="model.mfrStatus == 'W' || model.mfrStatus == 'S'" *ngIf="isAdmin || isEdit">
                            <mat-icon>archive</mat-icon><span>Withdraw</span>
                        </button>
                        <button mat-menu-item (click)="suspendMfr(model)" 
                                [ngStyle]="{'display': (model.mfrStatus == 'S' || model.mfrStatus == 'W') ? 'none' : 'block'}"
                                [disabled]="model.mfrStatus == 'S' || model.mfrStatus == 'W'" *ngIf="isAdmin || isEdit" >
                            <mat-icon>report_off</mat-icon>
                            <span>Suspend</span>
                        </button>
                        <button mat-menu-item (click)="deleteMfr(model.mfrAssoRecId, model.fileNumber, model.mfrPartySiteNumber, model.volumeType)"
                                *ngIf="isAdmin" [disabled]="model.mfrStatus == 'S'" [ngStyle]="{'display': (model.mfrStatus == 'S') ? 'none' : 'block'}">
                            <mat-icon>delete_forever</mat-icon>
                            <span>Delete</span>
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-elevation-z2 flex flex-grow mt-2" *ngIf="isTableView">
    <table mat-table
           [dataSource]="tableDataSource" multiTemplateDataRows matSort (matSortChange)="sortData($event)"
           [matSortDisableClear]="true" [matSortActive]="searchRequest.sortBy" [matSortDirection]="searchRequest.sortDirection">
        <ng-container matColumnDef="mfrStatus">
            <th mat-header-cell *matHeaderCellDef class="table-header">Status</th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                <div class="flex flex-wrap items-center">
                    <mat-icon *ngIf="element.mfrStatus == 'A'" class="text-label-green font-bold">stop</mat-icon>
                    <mat-icon *ngIf="element.mfrStatus == 'W'" class="text-label-dimgrey font-bold">stop</mat-icon>
                    <mat-icon *ngIf="element.mfrStatus == 'S'" class="text-label-blue font-bold">stop</mat-icon>
                    <span *ngIf="element.mfrStatus == 'A'"> Active</span>
                    <span *ngIf="element.mfrStatus == 'W'"> Withdrawn</span>
                    <span *ngIf="element.mfrStatus == 'S'"> Suspended</span>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="fileNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>File Number </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fileNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="fileCcn">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>CCN </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.fileCcn}}
            </td>
        </ng-container>

        <ng-container matColumnDef="volume">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Volume </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.volume}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrCompanyName">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>MFR Company Name </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrCompanyName}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrFactoryId">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Factory ID </th>
            <td mat-cell *matCellDef="let element" class="cell-left-align cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrFactoryId}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrType">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>Type </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrType}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrAccount">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>MFR Account No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrAccount}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrPartyNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header">MFR Org No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrPartyNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrPartySiteNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>MFR Partysite No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrPartySiteNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="mfrSubscriberNumber">
            <th mat-header-cell *matHeaderCellDef class="table-header" mat-sort-header>MFR Subscriber No </th>
            <td mat-cell *matCellDef="let element" class="cursor-pointer"
                (click)="expandedElement = expandedElement === element ? null : element">
                {{element.mfrSubscriberNumber}}
            </td>
        </ng-container>

        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef class="table-header">Options </th>
            <td mat-cell *matCellDef="let element">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu" *ngIf="isAdmin || isEdit">
                    <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="editMfrDetail(element)" *ngIf="isAdmin || isEdit" [disabled]="element.mfrStatus == 'S'">
                        <mat-icon>edit</mat-icon><span>Edit</span>
                    </button>
                    <button mat-menu-item (click)="reactivateMfr(element)" [disabled]="element.mfrStatus == 'A'"
                            *ngIf="isAdmin || isEdit" [ngStyle]="{'display': element.mfrStatus == 'A' ? 'none' : 'block'}">
                        <mat-icon>replay</mat-icon><span>Re-Activate</span>
                    </button>
                    <button mat-menu-item (click)="withdrawMfr(element.mfrAssoRecId, element.fileNumber, element.mfrPartySiteNumber, element.volumeType)"
                            [ngStyle]="{'display': (element.mfrStatus == 'S' || element.mfrStatus == 'W') ? 'none' : 'block'}"
                            [disabled]="element.mfrStatus == 'W' || element.mfrStatus == 'S'" *ngIf="isAdmin || isEdit">
                        <mat-icon>archive</mat-icon><span>Withdraw</span>
                    </button>
                    <button mat-menu-item (click)="suspendMfr(element)"
                            [ngStyle]="{'display': (element.mfrStatus == 'S' || element.mfrStatus == 'W') ? 'none' : 'block'}"
                            [disabled]="element.mfrStatus == 'S' || element.mfrStatus == 'W'" *ngIf="isAdmin || isEdit">
                        <mat-icon>report_off</mat-icon><span>Suspend</span>
                    </button>
                    <button mat-menu-item (click)="deleteMfr(element.mfrAssoRecId, element.fileNumber, element.mfrPartySiteNumber, element.volumeType)"
                            *ngIf="isAdmin" [disabled]="element.mfrStatus == 'S'" [ngStyle]="{'display': (element.mfrStatus == 'S') ? 'none' : 'block'}">
                        <mat-icon>delete_forever</mat-icon><span>Delete</span>
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
                <div class="table-element-detail"
                     [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                    <div class="pl-4">
                        <div class="flex flex-row mt-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.ownerCompanyName">
                                <span class="font-bold">Applicant Company Name:&nbsp;</span>
                                <span>{{ element.ownerCompanyName }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownerAccount">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Account No:&nbsp;</span>
                                <span>{{element.ownerAccount}}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownerPartyNumber">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Org No:&nbsp;</span>
                                <span>{{ element.ownerPartyNumber }}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownerPartySiteNumber">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant Partysite No:&nbsp;</span>
                                <span>{{element.ownerPartySiteNumber}}</span>
                            </ng-container>
                            <ng-container *ngIf="element.ownerCountryCode">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Applicant CountryCode:&nbsp;</span>
                                <span>{{element.ownerCountryCode}}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-row file-details-body pl-2 flex-wrap">
                            <ng-container>
                                <span class="font-bold">Is Listee:&nbsp;</span>
                                <mat-icon *ngIf="element.isListee === 'Y'; else elseBlock" class="expand-arrow text-center text-label-green">
                                    check_circle
                                </mat-icon>
                                <ng-template #elseBlock>
                                    <mat-icon class="expand-arrow rotate-90deg text-center text-label-orange">
                                        cancel
                                    </mat-icon>
                                </ng-template>
                            </ng-container>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container *ngIf="element.complementaryCCN">
                                <span class="font-bold">Comp CCN:&nbsp;</span>
                                <span>{{ formatCompCCN(element.complementaryCCN) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.serviceContract">
                                <div class="font-bold">Service Contract#:&nbsp;</div>
                                <span>{{ element.serviceContract }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.startDate">
                                <div class="font-bold">Start Date:&nbsp;</div>
                                <span>{{ formatDate(element.startDate) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.endDate">
                                <div class="font-bold">End Date:&nbsp;</div>
                                <span>{{ formatDate(element.endDate) }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.lastUpdatedBy">
                                <span class="font-bold">Last Updated By:&nbsp;</span>
                                <span>{{ element.lastUpdatedBy }}</span>
                                <span class="field-divider mx-2">&nbsp;</span>
                            </ng-container>
                            <ng-container *ngIf="element.wdrwlCode">
                                <span class="font-bold">Withdrawl Code:&nbsp;</span>
                                <span>{{ element.wdrwlCode }}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap"
                             *ngIf="element.ownerAddress1; let showDivider = false;">
                            <ng-container *ngIf="element.ownerAddress1 && !showDivider;let showDivider = true;">
                                <div class="font-bold">MFR Company Address:&nbsp;</div>
                            </ng-container>
                            <div class="flex flex-column file-details-body flex-wrap">
                                <ng-container *ngIf="element.ownerAddress1">
                                    <div>{{ element.ownerAddress1 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerAddress2">
                                    <div>{{ element.ownerAddress2 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerAddress3">
                                    <div>{{ element.ownerAddress3 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerAddress4">
                                    <div>{{ element.ownerAddress4 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerAddress5">
                                    <div>{{ element.ownerAddress5 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerAddress6">
                                    <div>{{ element.ownerAddress6 }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCity">
                                    <div>{{ element.ownerCity }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerProvince">
                                    <div>{{ element.ownerProvince }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerState">
                                    <div>{{ element.ownerState }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerCountry">
                                    <div>{{ element.ownerCountry }}</div>
                                </ng-container>
                                <ng-container *ngIf="element.ownerPostalCode">
                                    <div>{{ element.ownerPostalCode }}</div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="flex flex-row my-1 file-details-body p-2 flex-wrap">
                            <ng-container>
                                <span class="font-bold">IC/LC Information:&nbsp;</span>
                                <span><a class="viewinfo" (click)="viewIcLcInfo(element.mfrPartySiteNumber)">View Info</a></span>
                            </ng-container>
                            <ng-container *ngIf="element.deliverableItemInstance">
                                <span class="field-divider mx-2">&nbsp;</span>
                                <span class="font-bold">Deliverable Item Instance Name:&nbsp;</span>
                                <ng-container *ngFor="let deliverableItemInstance of element.deliverableItemInstance;let indexOf=index;">
                                    <span *ngIf="indexOf !== 0">{{ ',' }}</span>
                                    <span>{{ deliverableItemInstance.deliverableItemInstanceName}}</span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let element; columns: columnsToDisplay;" class="table-element-row"
            [class.table-expanded-row]="expandedElement === element">
        </tr>
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="table-detail-row"></tr>
    </table>
</div>