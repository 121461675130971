/* eslint-disable @typescript-eslint/indent */
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CreateEditFile, CreateEditFileResponse } from './create-file-detail.model';
import { ClientSettingsService } from '../core/services/client-settings.service';
import * as FileSaver from 'file-saver';

import {
    FileSearchRequestModel, FileSearchResultModel, FileSummary, FileWithdrawRequest,
    ReactivateFileRequest, NextControlNumber, DeleteHistoryOutput, FileSuspendRequest
} from './file-detail-search.model';

@Injectable({ providedIn: 'root' })
export class FileDetailsService {
    private webApiEndPoint: string;
    private readonly refreshSearchResult = new Subject<any>();
    public refreshSearchResult$ = this.refreshSearchResult.asObservable();
    private httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    private httpOptionsString = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }), 
        responseType: 'blob' as 'json'
    };

    constructor(private clientSetting: ClientSettingsService, private http: HttpClient) {
        this.webApiEndPoint = `${this.clientSetting.clientSettings.webApiEndpointUrl}api/File/`;
    }

    onRefreshSearchResult() {
        this.refreshSearchResult.next(); // This will trigger search API to fetch results again
    }

    getSearchResult(searchRequest: FileSearchRequestModel): Observable<FileSearchResultModel> {
        const searchResult = this.http.post<FileSearchResultModel>(`${this.webApiEndPoint}search`, searchRequest, this.httpOptions).pipe();
        return searchResult;
    }

    getFileDetails(fileRecId: number): Observable<FileSummary> {
        const fileDetails = this.http.get<FileSummary>(`${this.webApiEndPoint}${fileRecId}`, this.httpOptions).pipe();
        return fileDetails;
    }

    withdrawFile(withdrawFileRequest: FileWithdrawRequest) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}withdraw`, withdrawFileRequest, this.httpOptions).pipe();
        return result;
    }

    deleteFile(fileRecId: number, userId: string) {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}delete/${fileRecId}`, JSON.stringify(userId), this.httpOptions).pipe();
        return result;
    }

    addNewFile = (request: CreateEditFile): Observable<CreateEditFileResponse> => {
        const result = this.http.post<CreateEditFileResponse>(`${this.webApiEndPoint}create`, request, this.httpOptions).pipe();
        return result;
    };

    updateFileDetail = (request: CreateEditFile): Observable<boolean> => {
        const result = this.http.put<boolean>(`${this.webApiEndPoint}update`, request, this.httpOptions).pipe();
        return result;
    };

    checkCcnExists(ccnValue: string): Observable<boolean> {
        const result = this.http.post<boolean>(`${this.webApiEndPoint}CheckCcnExists`, JSON.stringify(ccnValue), this.httpOptions).pipe();
        return result;
    }

    exportToExcel = (searchRequest: FileSearchRequestModel) => {
        return this.http.post(`${this.webApiEndPoint}exportToExcel`, searchRequest, this.httpOptionsString).pipe();
    }

    reactivateFile = (fileRecId: number, lastUpdatedBy: string) => {
        const reactivateFileRequest: ReactivateFileRequest = { fileRecId: fileRecId, lastUpdatedBy: lastUpdatedBy }
        return this.http.put<boolean>(`${this.webApiEndPoint}reactivate`, reactivateFileRequest, this.httpOptions).pipe();
    }

    generateControlNumber(): Observable<NextControlNumber> {
        const result = this.http.get<NextControlNumber>(`${this.webApiEndPoint}generateControlNumber`, this.httpOptions).pipe();
        return result;
    }

    getDeleteHistory(fileNumber: string): Observable<DeleteHistoryOutput> {
        const result = this.http.get<DeleteHistoryOutput>(`${this.webApiEndPoint}getDeleteHistory/${fileNumber}`, this.httpOptions).pipe();
        return result;
    }

    suspendFile(request: FileSuspendRequest): Observable<number> {
        const result = this.http.put<number>(`${this.webApiEndPoint}suspend`, request, this.httpOptions).pipe();
        return result;
    }

    ecmCreateFolder(request: CreateEditFile): Observable<CreateEditFileResponse> {
        const result = this.http.post<CreateEditFileResponse>(`${this.webApiEndPoint}CreateFileFolderEcm`, request, this.httpOptions).pipe();
        return result;
    }

    checkIfAgencyFile = (fileRecId: number): Observable<boolean> => {
        return this.http.post<boolean>(`${this.webApiEndPoint}checkIfAgencyFile`, fileRecId, this.httpOptions).pipe();
    };
}
