import { Component, OnInit, AfterViewInit, OnDestroy, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { DataService } from '../../core/services/data.service';
import { Paginator } from '../../shared/paginator/paginator.model';
import { Constants } from '../../constants';
import { FileDetailsService } from '../file-details.service';
import { FileDetailSearch, FileSearchRequestModel, FileSearchResultModel, WithdrawOrDeleteEventModel } from '../file-detail-search.model';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { WdrwlCodesSummary, GenerateAuthPageModel } from '../../shared/utility.model';
import { CreateEditFile } from '../create-file-detail.model';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportConfirmDialogComponent } from '../../shared/export-confirm-dialog/export-confirm-dialog.component';
import * as FileSaver from 'file-saver';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { NgForm } from '@angular/forms';
import { ControlNumber } from '../file-detail-search.model'

@Component({
    selector: 'app-file-search',
    templateUrl: './file-search.component.html',
    styleUrls: ['./file-search.component.scss']
})
export class FileSearchComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('fileContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    activeLevel = 0;

    loading = false;
    isSearch = false;
    isSearching = false;
    noSearchResults = false;
    hasSearchActionOccurred = false;
    searchResultCount: number;
    showSpinner = false;
    isTableView = false;
    isCreateFileOnly: boolean;
    isAdmin: boolean;
    isEdit: boolean;

    prevSearchRequest: FileSearchRequestModel = {} as FileSearchRequestModel;
    sortOptions = Constants.FILE_SEARCH_SORT_OPTIONS;
    statusOptions = Constants.FILEVOL_SHOWBY_OPTIONS;
    searchResult: FileSearchResultModel;
    searchRequest: FileSearchRequestModel;
    withdrawalCodes: WdrwlCodesSummary[];
    currentSort = { active: '', direction: '' };
    fileSearchModel: FileSearchRequestModel = {
        fileNumber: '',
        ownerAccount: '',
        ccn: '',
        controlNumber: '',
        subscriberNumber: '',
        ownerCompanyName: '',
        partySiteNumber: '',
        orgNumber: '',
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: this.sortOptions[0].value.split('-')[1],
        sortBy: this.sortOptions[0].value.split('-')[0],
        filterBy: Constants.ALL
    };

    refreshSearchResultSubscription: Subscription;
    tourResultCardData = Constants.TOUR_TEMP_DATA.resultCard;
    showTourResultCard: boolean = false;

    constructor(
        private loadingSpinnerService: LoadingSpinnerService,
        private toastService: ToastNotificationService,
        private dataService: DataService,
        private searchService: FileDetailsService,
        private resolver: ComponentFactoryResolver,
        private modalService: NgbModal,
        private sideNavService: SideNavService,
        private previousRouteService: PreviousRouteService
    ) {
        this.refreshSearchResultSubscription = this.searchService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            if (this.dataService.isEmpty(this.fileSearchModel)) { this.getSearchResult(this.fileSearchModel); }
        });
    }

    toggleStepResulCard = () => {
        this.showTourResultCard = !this.showTourResultCard;
    }

    /* This method will get the withdrawal codes and description */
    getWithdrawalCodeDetails = () => {
        this.dataService.getWdrwlCodes().subscribe((details) => {
            this.dataService.setOption(Constants.WDRWL_CODES, details.results);
            this.withdrawalCodes = details.results;
        });
    }

    getRespOfficeCodes = () => {
        this.dataService.getRespOfcCodes().subscribe((details) => {
            this.dataService.setOption(Constants.RESP_OFC_CODE, details.results);
        });
    }

    onWithdrawOrDelete = (withdrawOrDeleteEvent: WithdrawOrDeleteEventModel) => {
        document.querySelector('mat-sidenav-content').scrollTop = 0;
        if (withdrawOrDeleteEvent.value) {
            const successMessage = `The File detail was ${withdrawOrDeleteEvent.action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.onSearch(this.fileSearchModel);
        }
        else {
            const withdrawOrDeleteMsg = withdrawOrDeleteEvent.action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the file. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    onSortPaginationChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileSearchModel);
        if (result) { this.fileSearchModel.pageNumber = sortingEvent.page; }
        else { this.fileSearchModel.pageNumber = 1; }
        this.fileSearchModel.pageSize = sortingEvent.pageSize;
        this.getSearchResult(this.fileSearchModel);
    }

    onSortByChange = (sortingEvent: Paginator) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileSearchModel);
        if (!result) { this.fileSearchModel.pageNumber = 1; }
        this.fileSearchModel.sortBy = sortingEvent.sortBy.split('-')[0];
        this.fileSearchModel.sortDirection = sortingEvent.sortBy.split('-')[1];
        this.getSearchResult(this.fileSearchModel);
    }

    /* To sort the values based on the sort option chosen in the table headers - @param sortingEvent */
    onSortingChange = (sortingEvent: FileSearchRequestModel) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileSearchModel);
        if (!result) { this.fileSearchModel.pageNumber = 1; }
        this.fileSearchModel.sortBy = sortingEvent.sortBy;
        this.fileSearchModel.sortDirection = sortingEvent.sortDirection;
        this.getSearchResult(this.fileSearchModel);
    }

    onViewChange = (sortingEvent: Paginator) => {
        this.isTableView = sortingEvent.isTableView;
    }

    createNewFile = () => {
        let controlNumber: ControlNumber[];
        controlNumber = [
            {
                controlNumberId: 0,
                controlNumber: '',
                fileRecId: null,
                action: Constants.ADD
            }
        ];
        const model: CreateEditFile = {
            fileRecId: 0,
            fileType: '',
            ownerAccountNumber: '',
            orgNumber: '',
            partySiteNumber: '',
            subscriberNumber: '',
            ownerCompanyName: '',
            ownerCompanyAddress1: '',
            ownerCompanyAddress2: '',
            ownerCompanyAddress3: '',
            ownerCompanyAddress4: '',
            ownerCompanyAddress5: '',
            ownerCompanyAddress6: '',
            ownerPostalCode: '',
            ownerCity: '',
            ownerState: '',
            ownerCountry: '',
            ownerProvince: '',
            fileCcn: '',
            ownerFileNo: '',
            partyNumber: '',
            startDate: '',
            lastUpdatedBy: '',
            lastUpdatedOn: '',
            wdrwlStatus: 'A',
            wdrlRecId: 0,
            controlNumbers: controlNumber
        }
        this.sideNavService.createEditFile(model);
    }

    onSearch = (formValues) => {
        const result = this.dataService.isObjEquals(this.prevSearchRequest, this.fileSearchModel);
        if (!this.hasSearchActionOccurred) {
            this.fileSearchModel.pageNumber = 1;
            this.fileSearchModel.pageSize = Constants.DEFAULT_PAGE_SIZE;
            this.fileSearchModel.sortDirection = this.sortOptions[0].value.split('-')[1];
            this.fileSearchModel.sortBy = this.sortOptions[0].value.split('-')[0];
        }
        else if (this.hasSearchActionOccurred && !result) {
            this.fileSearchModel.pageNumber = 1;
        }
        this.getSearchResult(formValues);
    }

    getSearchResult = (formValues) => {
        if (this.dataService.isEmpty(formValues)) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.prevSearchRequest = Object.assign({}, this.fileSearchModel);
            this.dataService.setOption(Constants.FILE_SEARCH_REQUEST_OBJECT, this.fileSearchModel);
            this.searchService.getSearchResult(this.fileSearchModel)
                .subscribe((searchResult) => {
                    componentRef.destroy();
                    this.showSpinner = false;
                    this.searchResult = searchResult;
                    this.searchResultCount = searchResult.paging.totalRecords;
                    this.dataService.setOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID, []);
                    this.currentSort = {
                        active: this.fileSearchModel.sortBy,
                        direction: this.fileSearchModel.sortDirection
                    };

                    if (this.searchResultCount === 0) {
                        this.isSearch = false;
                        const errorMessage = 'No Data found for the specified search criteria';
                        const type = ToastNotificationType.infoBlue;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = true;
                        this.dataService.navigateToPageTop();
                        return;
                    }
                    else {
                        this.hasSearchActionOccurred = true;
                        this.noSearchResults = false;
                        this.isSearch = true;
                    }
                }, (error) => {
                    componentRef.destroy();
                    const errorMessage = 'Error while retrieving the data';
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.searchResultCount = 0;
                    this.showSpinner = false;
                    this.isSearch = false;
                    this.hasSearchActionOccurred = true;
                    this.noSearchResults = true;
                    this.isTableView = false;
                    this.dataService.navigateToPageTop();
                });
        }
        else {
            const errorMessage = 'Please enter the search criteria in the form below';
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            this.searchResultCount = 0;
            this.showSpinner = false;
            this.isSearch = false;
            this.hasSearchActionOccurred = false;
            this.noSearchResults = false;
            this.isTableView = false;
            this.dataService.navigateToPageTop();
        }
    }

    resetForm(fileDetailSearchForm: NgForm): void {
        // This will clear the previous search request data

        fileDetailSearchForm.resetForm({
            fileNumber: '',
            ownerAccount: '',
            ccn: '',
            controlNumber: '',
            subscriberNumber: '',
            ownerCompanyName: '',
            partySiteNumber: '',
            orgNumber: '',
            exactSearch: true,
            filterBy: Constants.ALL
        });

        this.isSearch = false;
        this.isTableView = false;
        this.searchResultCount = 0;
        this.hasSearchActionOccurred = false;
        this.searchResult = {} as FileSearchResultModel;
        this.prevSearchRequest = {} as FileSearchRequestModel;
        this.dataService.setOption(Constants.FILE_SEARCH_REQUEST_OBJECT, {});
    }

    generateAuthPage = () => {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const fileRec = this.dataService.getOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID);
        const empId = this.dataService.getLastUpdatedBy();
        if (fileRec != undefined && fileRec.length > 0) {
            // Call the api to generate the auth page
            const request: GenerateAuthPageModel = {
                inputType: "FILE",
                empId: empId,
                records: fileRec
            }
            this.dataService.generateAuthPage(request).subscribe(result => {
                // Show the success message
                componentRef.destroy();
                if (result["OverallStatus"] == Constants.SUCCESS) {
                    const successMessage = `Successfully generated the Auth page for selected file`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.dataService.setOption(Constants.FILE_GEN_AUTH_PAGE_REC_ID, []);
                    this.searchService.onRefreshSearchResult();
                }
                else {
                    var errorMessage = `Error while generating the Auth page for selected file`;
                    if (result["ErrorMessage"] != "") { errorMessage = result["ErrorMessage"]; }
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                componentRef.destroy();
                const errorMessage = `Error while generating the Auth page for selected file`;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }
        else {
            componentRef.destroy();
            const errorMessage = `Please select the file(s) to proceed.`;
            const type = ToastNotificationType.infoBlue;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    exportToExcel = () => {
        this.loading = true;
        const fileName = `FileDetails-${this.dataService.customDateFormat(null)}.xlsx`;
        const dialogRef = this.modalService.open(ExportConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.result.then(result => {
            if (result.action === 'yes') {
                if (result.selectedValue === 'true') {
                    this.fileSearchModel.pageNumber = 1;
                    this.fileSearchModel.pageSize = this.searchResultCount;
                }
                this.searchService.exportToExcel(this.fileSearchModel).subscribe((object: Blob) => {
                    if (object.size && object.size > 0) { FileSaver.saveAs(object, fileName); }
                    else {
                        const errorMessage = `Error while trying to export the data to excel`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                    this.loading = false;
                });
            }
        }).catch(res => {
            if (res.action === 'no') { this.loading = false; }
        });
    }

    ngOnInit(): void {
        this.activeLevel = 1;
        this.pageName = 'File Details';
        this.dataService.changeMessage(Constants.PRIMARY_LIGHT);
        const obj = this.dataService.getOption(Constants.FILE_SEARCH_REQUEST_OBJECT)
        if (this.dataService.getOption(Constants.FILE_SEARCH_REQUEST_OBJECT) && Object.keys(obj).length > 0) {
            this.fileSearchModel = this.dataService.getOption(Constants.FILE_SEARCH_REQUEST_OBJECT);
            if (this.dataService.isEmpty(this.fileSearchModel)) { this.onSearch(this.fileSearchModel); }
        }
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
        this.isCreateFileOnly = this.dataService.getOption(Constants.IS_CREATEFILEONLY_USER);
        this.getWithdrawalCodeDetails();
        this.getRespOfficeCodes();
    }

    ngAfterViewInit(): void {
        // Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
        // Add 'implements AfterViewInit' to the class.
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }
}
