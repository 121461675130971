import {
    Component, Input, OnInit, Output, EventEmitter,
    ViewContainerRef, ViewChild, ComponentFactoryResolver
} from '@angular/core';

import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { Sort } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { state, style, trigger } from '@angular/animations';
import { MatTableDataSource } from "@angular/material/table";
import { WdrwlCodesSummary } from '../../shared/utility.model';
import { WithdrawOrDeleteMfr } from '../withdraw-or-delete-mfr';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { SuspendMFRRequest, DeliverableItemInstance } from '../mfr-detail-search.model';
import { ReactivateMfr, ManufacturerSearchRequest } from '../mfr-detail-search.model';
import { ManufacturerSummary, WithdrawOrDeleteEventModel } from '../mfr-detail-search.model';
import { IclcInformationComponent } from '../../shared/iclc-information/iclc-information.component';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { CreateEditMfrModel } from '../create-new-mfr/create-mfr.model';
import { DataService } from '../../core/services/data.service';
import { MfrDetailService } from '../mfr-detail.service';

@Component({
    selector: 'app-mfr-search-result-card',
    templateUrl: './mfr-search-result-card.component.html',
    styleUrls: ['./mfr-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ])
    ]
})
export class MfrSearchResultCardComponent implements OnInit {
    @ViewChild('mfrSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild(MatSort) sort: MatSort;
    private tableDataSource = new MatTableDataSource<ManufacturerSummary>([]);
    columnsToDisplay = Constants.MFR_RESULT_TABLE_COLUMNS;
    expandedElement: ManufacturerSummary | null;
    mlVolumeString = "ML VOLUME";
    sortBy = Constants.SortBy;
    indexExpanded: number = -1;
    isAdmin: boolean;
    isEdit: boolean;

    searchDataResults: ManufacturerSummary[] | null;
    searchRequest: ManufacturerSearchRequest = {
        fileNumber: '',
        mfrAccount: '',
        ownerAccount: '',
        volume: '',
        deliverableItemInstance: '',
        subscriberNumber: '',
        partySiteNumber: '',
        fileRecId: 0,
        fileVolRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "asc",
        sortBy: "Volume",
        filterBy: Constants.ALL
    };

    @Input() isTableView: boolean;
    @Input() wdrwlCodes: WdrwlCodesSummary[];
    @Input() set currentSort(value: Sort) {
        this.searchRequest.sortBy = value.active;
        this.searchRequest.sortDirection = value.direction;
    }
    @Input() set tableData(value: ManufacturerSummary[]) {
        if (this.isTableView) { this.tableDataSource = new MatTableDataSource(value); }
        else { this.searchDataResults = value; this.indexExpanded = -1; }
    }
    @Output() withdrawOrDeleteAction: EventEmitter<WithdrawOrDeleteEventModel> = new EventEmitter();
    @Output() sortingChangeAction: EventEmitter<ManufacturerSearchRequest> = new EventEmitter();

    constructor(
        private dataService: DataService,
        private router: Router,
        private withdrawOrDelete: WithdrawOrDeleteMfr,
        private sideNavService: SideNavService,
        private modalService: NgbModal,
        private toastService: ToastNotificationService,
        private resolver: ComponentFactoryResolver,
        private mfrService: MfrDetailService
    ) { }

    ngOnInit(): void {
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
    }

    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    /**
     * This will give a comma seperated string
     * of comp ccn for the particular manufacturer
     * @param compCcn
     */
    formatCompCCN = (compCcn) => {
        return this.dataService.formatCompCCN(compCcn);
    }

    sortData(sort: Sort) {
        const SortByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === SortByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else { this.searchRequest.sortDirection = this.sortBy.ASC; }

        this.searchRequest.sortBy = SortByColumn;
        this.sortingChangeAction.emit(this.searchRequest);
    }

    withdrawMfr(mfrAssoRecId: number, fileNumber: string, partySiteNumber: string, volumeType: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.withdrawMfr(mfrAssoRecId, this.wdrwlCodes, fileNumber, partySiteNumber, volumeType, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
        });
    }

    deleteMfr(mfrAssoRecId: number, fileNumber: string, partySiteNumber: string, volumeType: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.deleteMfr(mfrAssoRecId, fileNumber, partySiteNumber, volumeType, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: true }); }
            else { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: false }); }
        }, error => {
            componentRef.destroy(); // To destroy the load spinner component
            this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
        });
    }

    editMfrDetail(modelData: ManufacturerSummary) {
        let deliverableInstance: DeliverableItemInstance[];
        if (modelData.deliverableItemInstance) {
            deliverableInstance = modelData.deliverableItemInstance;
        } else {
            deliverableInstance = [
                {
                    deliverableItemInstanceId: 0,
                    deliverableItemInstanceName: '',
                    action: Constants.UPDATED
                },
            ];
        }

        const model: CreateEditMfrModel = {
            fileRecId: 0,
            fileVolRecId: modelData.fileVolRecId,
            mfrAssoRecId: modelData.mfrAssoRecId,
            fileNumber: modelData.fileNumber,
            volume: modelData.volume,
            startDate: this.dataService.formatDate(modelData.startDate),
            endDate: this.dataService.formatDate(modelData.endDate),
            deliverableItemInstance: deliverableInstance,
            mfrAccountNumber: modelData.mfrAccount,
            orgNumber: modelData.orgNumber,
            mfrPartySiteNumber: modelData.mfrPartySiteNumber,
            mfrPartyNumber: modelData.mfrPartyNumber,
            mfrSubscriberNumber: modelData.mfrSubscriberNumber,
            wdrwlRecId: modelData.wdrlRecId,
            wdrwlCode: modelData.wdrwlCode,
            mfrCompanyName: modelData.mfrCompanyName,
            mfrCompanyAddress: '',
            serviceContract: modelData.serviceContract,
            mfrType: modelData.mfrType,
            mfrFactoryId: modelData.mfrFactoryId,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            mfrStatus: modelData.mfrStatus,
            isAddToAllVolume: false,
            mfrAddress1: '',
            mfrAddress2: '',
            mfrAddress3: '',
            mfrAddress4: '',
            mfrAddress5: '',
            mfrAddress6: '',
            mfrCity: '',
            mfrProvince: '',
            mfrState: '',
            mfrCountry: '',
            mfrPostalCode: '',
            volumeList: ''
        };
        this.sideNavService.createEditMfr(model, this.wdrwlCodes, 'edit');
    }

    viewIcLcInfo(partySiteNumber: string) {
        const dialogRef = this.modalService.open(IclcInformationComponent, { windowClass: 'modal-zindex' });
        dialogRef.componentInstance.partySiteNumber = partySiteNumber;
    }

    suspendMfr(model: ManufacturerSummary) {
        const fileVolume = model.volume;
        const fileNumber = model.fileNumber;
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);

        dialogRef.componentInstance.data = {
            title: `Suspend Manufacturer - ${model.fileNumber} : ${model.volume}`,
            confirmationInfo: `Are you sure you want to suspend the Manufacturer?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result.then(result => {
            const lastUpdatedBy = this.dataService.getLastUpdatedBy();
            const lastUpdatedDate = this.dataService.getLastUpdatedDate();
            const endDate = this.dataService.customDateFormat(null);
            const suspendRequest: SuspendMFRRequest = {
                partySiteNumber: model.mfrPartySiteNumber,
                lastUpdatedOn: lastUpdatedDate,
                mfrRecId: model.mfrAssoRecId,
                fileNumber: model.fileNumber,
                lastUpdatedBy: lastUpdatedBy,
                endDate: endDate,
                wdrlRecId: 0,
                status: 'S'
            }

            this.mfrService.suspendMfr(suspendRequest).subscribe(result => {
                const returnCode = !isNaN(Number(result)) ? Number(result) : 99;
                componentRef.destroy(); // To destroy the load spinner component
                this.dataService.navigateToPageTop();
                if (returnCode == 1) {
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const successMessage = `The manufacuturer detail ${fileNumber}-${fileVolume} was suspended successfully`;
                    const message = new ToastNotificationMessage(successMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                    this.mfrService.onRefreshSearchResult();
                }
                else if (returnCode == 0) {
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const errorMessage = `The manufacturer suspend action has failed : ${fileNumber}-${fileVolume}.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
                else {
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const errorMessage = `Error while trying to suspend the manufacuturer : ${fileNumber}-${fileVolume}.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.dataService.navigateToPageTop();
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                componentRef.destroy(); // To destroy the load spinner component
                const errorMessage = `Error while trying to suspend the manufacuturer : ${fileNumber}-${fileVolume}.`;
                const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }

    reactivateMfr(model: ManufacturerSummary) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);

        /*
         * If its a ML volume MFR, then we should not
         * show the re-activate for all volume checkbox
         * since ML volume MFR needs to be activate one at
         * a time
         */
        if (model.volumeType != this.mlVolumeString) {
            dialogRef.componentInstance.showWithdrawOrDeleteAll = true;
            dialogRef.componentInstance.checkboxMessage = "Re-activate MFR across all the Volumes in the File";
        }
        dialogRef.componentInstance.data = {
            title: 'Re-Activate Manufacturer',
            confirmationInfo: `Are you sure you want to re-activate the manufacuturer(s)?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        const input: ReactivateMfr = {
            fileVolRecId: model.fileVolRecId,
            fileNumber: model.fileNumber,
            mfrRecId: model.mfrAssoRecId,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate(),
            partySiteNumber: model.mfrPartySiteNumber
        }
        dialogRef.result.then(result => {
            if (result.showWithdrawOrDeleteValue) {
                // Code block to re-active manufacturer across all active volumes except ML Volumes
                this.mfrService.reactivateMfrOfAllVolume(input).subscribe(result => {
                    componentRef.destroy(); // To destroy the load spinner component
                    if (result.result) {
                        this.dataService.navigateToPageTop();
                        const successMessage = result.message;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.mfrService.onRefreshSearchResult();
                    }
                    else {
                        this.dataService.navigateToPageTop();
                        const errorMessage = result.message;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                }, error => {
                    this.dataService.navigateToPageTop();
                    componentRef.destroy(); // To destroy the load spinner component
                    const errorMessage = `Error while trying to re - activate the manufacturer(s) across all active volume(s) of ${model.fileNumber} file.`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                });
            }
            else {
                this.mfrService.reactivateMfr(input).subscribe(result => {
                    componentRef.destroy(); // To destroy the load spinner component
                    if (result.result) {
                        this.dataService.navigateToPageTop();
                        const successMessage = `The manufacturer of volume ${model.volume} of ${model.fileNumber} file has been re-activated successfully.`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                        this.mfrService.onRefreshSearchResult();
                    }
                    else {
                        this.dataService.navigateToPageTop();
                        const errorMessage = result.message;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                }, error => {
                    this.dataService.navigateToPageTop();
                    componentRef.destroy(); // To destroy the load spinner component
                    const errorMessage = result.message;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                });
            }
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }
}