import {
    Component, Input, OnInit, Output, EventEmitter,
    ViewContainerRef, ViewChild, ComponentFactoryResolver
} from '@angular/core';

import { Router } from '@angular/router';
import { Constants } from '../../constants';
import { Sort } from '@angular/material/sort';
import { MatSort } from '@angular/material/sort';
import { ListeeService } from '../listee.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { state, style, trigger } from '@angular/animations';
import { MatTableDataSource } from "@angular/material/table";
import { DataService } from '../../core/services/data.service';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ListeeSummary, ListeeSearchRequest, SuspendListeeRequest } from '../listee.model';
import { WithdrawOrDeleteEventModel, ReactivateListeeModel } from '../listee.model';
import { CreateListeeModel } from '../create-listee/create-listee.model';
import { WithdrawOrDeleteListee } from '../withdraw-or-delete-listee';

@Component({
    selector: 'app-listee-search-result-card',
    templateUrl: './listee-search-result-card.component.html',
    styleUrls: ['./listee-search-result-card.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
        ])
    ]
})
export class ListeeSearchResultCardComponent implements OnInit {
    @ViewChild('listeeSearchContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    @ViewChild(MatSort) sort: MatSort;
    private tableDataSource = new MatTableDataSource<ListeeSummary>([]);
    columnsToDisplay = Constants.LISTEE_RESULT_TABLE_COLUMNS;
    expandedElement: ListeeSummary | null;
    sortBy = Constants.SortBy;
    indexExpanded: number = -1;
    isAdmin: boolean;
    isEdit: boolean;

    searchDataResults: ListeeSummary[] | null;
    searchRequest: ListeeSearchRequest = {
        fileNumber: '',
        volume: '',
        ownerAccountNumber: '',
        listeeType: Constants.ALL,
        listeeAccountNumber: '',
        listeeFileNumber: '',
        partySiteNumber: '',
        subscriberNumber: '',
        fileRecId: 0,
        fileVolRecId: 0,
        exactSearch: true,
        pageNumber: 1,
        pageSize: Constants.DEFAULT_PAGE_SIZE,
        sortDirection: "asc",
        sortBy: "volume",
        filterBy: Constants.ALL,
    };

    @Input() isTableView: boolean;
    @Input() set currentSort(value: Sort) {
        this.searchRequest.sortBy = value.active;
        this.searchRequest.sortDirection = value.direction;
    }
    @Input() set tableData(value: ListeeSummary[]) {
        if (this.isTableView) { this.tableDataSource = new MatTableDataSource(value); }
        else { this.searchDataResults = value; this.indexExpanded = -1; }
    }
    @Output() withdrawOrDeleteAction: EventEmitter<WithdrawOrDeleteEventModel> = new EventEmitter();
    @Output() sortingChangeAction: EventEmitter<ListeeSearchRequest> = new EventEmitter();

    constructor(
        private dataService: DataService,
        private router: Router,
        private sideNavService: SideNavService,
        private withdrawOrDelete: WithdrawOrDeleteListee,
        private resolver: ComponentFactoryResolver,
        private modalService: NgbModal,
        private toastService: ToastNotificationService,
        private listeeService: ListeeService) { }

    ngOnInit(): void {
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
    }

    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    /**
     * This will give a comma seperated string
     * of comp ccn for the particular manufacturer
     * @param compCcn
     */
    formatCompCCN = (compCcn) => {
        return this.dataService.formatCompCCN(compCcn);
    }

    sortData(sort: Sort) {
        const SortByColumn = sort.active; // This will give the name of the column currently being sorted
        if (this.searchRequest.sortBy === SortByColumn) {
            switch (this.searchRequest.sortDirection) {
                case this.sortBy.ASC:
                    this.searchRequest.sortDirection = this.sortBy.DESC;
                    break;
                case this.sortBy.DESC:
                    this.searchRequest.sortDirection = this.sortBy.ASC;
                    break;
                default: break;
            }
        } else { this.searchRequest.sortDirection = this.sortBy.ASC; }

        this.searchRequest.sortBy = SortByColumn;
        this.sortingChangeAction.emit(this.searchRequest);
    }

    withdrawListee(listeeAssoRecId: number, fileVolRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.withdrawListee(listeeAssoRecId, fileVolRecId, componentRef)
            .subscribe(result => {
                componentRef.destroy(); // To destroy the load spinner component
                if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: true }); }
                else { this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false }); }
            }, error => {
                componentRef.destroy(); // To destroy the load spinner component
                this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
            });
    }

    deleteListee(listeeAssoRecId: number, fileVolRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.deleteListee(listeeAssoRecId, fileVolRecId, componentRef)
            .subscribe(result => {
                componentRef.destroy(); // To destroy the load spinner component
                if (result) { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: true }); }
                else { this.withdrawOrDeleteAction.emit({ action: Constants.DELETE, value: false }); }
            }, error => {
                componentRef.destroy(); // To destroy the load spinner component
                this.withdrawOrDeleteAction.emit({ action: Constants.WITHDRAWN, value: false });
            });
    }

    getCompanyAddress(modelData: ListeeSummary) {
        let address = "";
        if (modelData.ownerCompanyAddress1) {
            address = `${modelData.ownerCompanyAddress1}`;
        }
        if (modelData.ownerCompanyAddress2) {
            address += `\n${modelData.ownerCompanyAddress2}`;
        }
        if (modelData.ownerCity) {
            address += `\n${modelData.ownerCity}`;
        }
        if (modelData.ownerState) {
            address += `\n${modelData.ownerState}`;
        }
        if (modelData.ownerCountry) {
            address += `\n${modelData.ownerCountry}`;
        }
        if (modelData.ownerPostalCode) {
            address += `\n${modelData.ownerPostalCode}`;
        }
        return address;
    }

    updateListee(modelData: ListeeSummary) {
        const model: CreateListeeModel = {
            fileVolRecId: modelData.basicFileVolRecId,
            listeeRecId: modelData.listeeAssoRecId,
            listeeFileNumber: modelData.listeeFileNumber,
            accountNumber: modelData.listeeOwnerAccount,
            orgNumber: modelData.orgNumber,
            listeePartySiteNumber: modelData.listeePartySiteNumber,
            subscriberNumber: modelData.listeeSubscriberNumber,
            companyName: modelData.ownerCompanyName,
            companyAddress: this.getCompanyAddress(modelData),
            associationType: modelData.listeeAssociationType,
            startDate: this.dataService.formatDate(modelData.startDate),
            endDate: this.dataService.formatDate(modelData.endDate),
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            isActive: modelData.listeeStatus,
            listeePartyNumber: modelData.listeePartyNumber,
            listeeAddress1: modelData.ownerCompanyAddress1,
            listeeAddress2: modelData.ownerCompanyAddress2,
            listeeAddress3: modelData.ownerCompanyAddress3,
            listeeAddress4: modelData.ownerCompanyAddress4,
            listeeAddress5: modelData.ownerCompanyAddress5,
            listeeAddress6: modelData.ownerCompanyAddress6,
            listeeCity: modelData.ownerCity,
            listeeProvince: modelData.ownerProvince,
            listeeState: modelData.ownerState,
            listeeCountry: modelData.ownerCountry,
            listeePostalCode: modelData.ownerPostalCode,
            isAddToAllVolume: false
        };
        this.sideNavService.createListee(model, 'edit');
    }

    suspendListee(model: ListeeSummary) {
        const fileVolume = model.volume;
        const fileNumber = model.listeeFileNumber;
        const listeeRecId = model.listeeAssoRecId;
        const fileVolRecId = model.basicFileVolRecId;
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);

        dialogRef.componentInstance.data = {
            title: `Suspend Listee - ${fileNumber} : ${fileVolume}`,
            confirmationInfo: `Are you sure you want to suspend the listee?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result.then(result => {
            const lastUpdatedBy = this.dataService.getLastUpdatedBy();
            const lastUpdatedDate = this.dataService.getLastUpdatedDate();
            const endDate = this.dataService.customDateFormat(null);
            const suspendRequest: SuspendListeeRequest = {
                fileVolRecId: fileVolRecId,
                listeeAssoRecId: listeeRecId,
                lastUpdatedOn: lastUpdatedDate,
                lastUpdatedBy: lastUpdatedBy,
                endDate: endDate,
                isActive: 'S'
            }

            this.listeeService.suspendListee(suspendRequest).subscribe(result => {
                const returnCode = !isNaN(Number(result)) ? Number(result) : 99;
                componentRef.destroy(); // To destroy the load spinner component
                this.dataService.navigateToPageTop();
                if (returnCode == 1) {
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const successMessage = `The liste file detail ${fileNumber}-${fileVolume} was suspended successfully`;
                    const message = new ToastNotificationMessage(successMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                    this.listeeService.onRefreshSearchResult();
                }
                else if (returnCode == 0) {
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const errorMessage = `The listee suspend action has failed : ${fileNumber}-${fileVolume}.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
                else {
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const errorMessage = `Error while trying to suspend the listee : ${fileNumber}-${fileVolume}.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.dataService.navigateToPageTop();
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                componentRef.destroy(); // To destroy the load spinner component
                const errorMessage = `Error while trying to suspend the listee : ${fileNumber}-${fileVolume}.`;
                const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }

    reactivateListee(fileVolRecId: number, listeeRecId: number, listeeType: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Re-Activate Volume',
            confirmationInfo: `Are you sure you want to re-activate the listee?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        const input: ReactivateListeeModel = {
            basicFileVolRecId: fileVolRecId,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate(),
            listeeAssoRecId: listeeRecId,
            listeeType: listeeType
        }
        dialogRef.result.then(result => {
            this.listeeService.reactivateListee(input).subscribe(result => {
                componentRef.destroy(); // To destroy the load spinner component
                if (result.result) {
                    this.dataService.navigateToPageTop();
                    const successMessage = `Listee has been re-activated successfully.`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.listeeService.onRefreshSearchResult();
                }
                else {
                    this.dataService.navigateToPageTop();
                    const errorMessage = result.message;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                this.dataService.navigateToPageTop();
                componentRef.destroy(); // To destroy the load spinner component
                const errorMessage = result.message;
                const type = ToastNotificationType.error;
                const placement = ToastNotificationPlacement.Body;
                const message = new ToastNotificationMessage(errorMessage, type, placement);
                this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }
}