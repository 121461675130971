import {
    Component, OnInit, AfterViewInit,
    ViewContainerRef, ViewChild, ComponentFactoryResolver
} from '@angular/core';

import { Constants } from '../../constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FileDetailsService } from '../file-details.service';
import { DataService } from '../../core/services/data.service';
import { AuthService } from '../../core/services/auth.service';
import { WithdrawOrDeleteFile } from '../withdraw-or-delete-file';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SideNavService } from '../../shared/side-nav/sidenav.service';
import { FileVolumeSummary } from '../../file-volume/file-volume-detail-search.model';
import { LoadingSpinnerService } from '../../shared/loading-spinner/loading-spinner.service';
import { ToastNotificationType } from '../../shared/toastnotification/toastNotificationType.model';
import { ToastNotificationService } from '../../shared/toastnotification/toastnotification.service';
import { ToastNotificationMessage } from '../../shared/toastnotification/toastNotificationMessage.model';
import { DeleteHistoryOutput, DeleteFileDetail, FileSummary } from '../file-detail-search.model';
import { CreateNewFile, UpdateFileRequest, DeleteFileVolumeDetail } from '../file-detail-search.model';
import { ControlNumber, DeleteMfrAssociationDetail, FileSuspendRequest } from '../file-detail-search.model';
import { ToastNotificationPlacement } from '../../shared/toastnotification/toast-notification-placement.model';
import { CreateEditFileVolume } from '../../file-volume/create-file-volume/create-file-volume.model';
import { ConfirmDialogComponent } from '../../shared/confirm-dialog/confirm-dialog.component';
import { PreviousRouteService } from '../../shared/previous-route.service';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { MatTableDataSource } from '@angular/material/table';
import { CreateEditFile } from '../create-file-detail.model';
import { MatTabsModule } from '@angular/material/tabs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-file-detail-view',
    templateUrl: './file-detail-view.component.html',
    styleUrls: ['./file-detail-view.component.scss']
})
export class FileDetailsViewComponent implements OnInit, AfterViewInit {
    @ViewChild('fileDetailViewContainer', { static: true, read: ViewContainerRef }) entry: ViewContainerRef;
    public pageName = '';
    levelTwoTitle = '';
    activeLevel = 0;
    loading = false;

    fileType: string[] = Constants.FILE_TYPE;
    modelData: FileSummary;

    createNewFileModel: CreateNewFile = {
        ownerAccountNumber: '',
        orgNumber: '',
        partySiteNumber: '',
        ownerCompanyName: '',
        ownerCompanyAddress: '',
        subscriberNumber: '',
        ccn: '',
        ownerFileNumber: '',
        fileType: ''
    };

    editFileForm: FormGroup;
    isAdmin: boolean;
    isEdit: boolean;
    refreshSearchResultSubscription: Subscription;

    displayColFileDetail: string[] = ['fileNumber', 'fileType', 'accountNo',
        'partyNo', 'partySiteNo', 'companyName', 'startDate', 'endDate',
        'deletedOn', 'deletedBy', 'deleteLevel'];

    displayColFileVolume: string[] = ['fileNumber', 'fileVolume', 'volumeType',
        'basic_CCN', 'accountNo', 'partyNo', 'partySiteNo', 'companyName',
        'startDate', 'endDate', 'deletedOn', 'deletedBy', 'deleteLevel'];

    displayColMfrDetail: string[] = ['fileNumber', 'fileVolume',
        'accountNo', 'partyNo', 'partySiteNo', 'mFR_Type', 'companyName',
        'startDate', 'endDate', 'deletedOn', 'deletedBy', 'deleteLevel'];

    deletedData: DeleteHistoryOutput;
    dataFileSource = new MatTableDataSource([]);
    dataFileVolumeSource = new MatTableDataSource([]);
    dataMfrAssociationSource = new MatTableDataSource([]);
    isDeletedFetch: boolean = true;
    deletedHistory: boolean;

    constructor(
        private router: Router,
        private loadingSpinnerService: LoadingSpinnerService,
        private dataService: DataService,
        private activatedRoute: ActivatedRoute,
        private withdrawOrDelete: WithdrawOrDeleteFile,
        private toastService: ToastNotificationService,
        private fileDetailService: FileDetailsService,
        private sideNavService: SideNavService,
        private fb: FormBuilder,
        private resolver: ComponentFactoryResolver,
        private previousRouteService: PreviousRouteService,
        private modalService: NgbModal)
    {
        this.dataService.changeMessage('#FFFFFF');
        this.dataService.getModelData().subscribe(result => this.modelData = result);
        if (!this.modelData) {
            this.modelData = <FileSummary & FileVolumeSummary>(this.activatedRoute.snapshot.data.modelData);
            this.dataService.setModelData(this.modelData);
        }
        this.refreshSearchResultSubscription = this.fileDetailService.refreshSearchResult$.subscribe(res => {
            // Fetch the data again
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.fileDetailService.getFileDetails(this.modelData.fileRecId).subscribe((result) => {
                componentRef.destroy();
                if (result) { this.modelData = result; }
            }, error => { componentRef.destroy(); });
        });
    }

    get f() { return this.editFileForm.controls; }

    formatDate(dateObj: string, splitter: string = "-") {
        return this.dataService.formatDate(dateObj, splitter);
    }

    /**
     * This will give a comma seperated string
     * of comp ccn for the particular manufacturer
     * @param compCcn
     */
    formatCompCCN = (compCcn) => {
        return this.dataService.formatCompCCN(compCcn);
    }

    getDate = (date: string) => {
        return this.dataService.getDate(date);
    }

    onTabChange(event) {
        if (event.tab.textLabel == "Delete History" && this.isDeletedFetch) {
            const factory = this.resolver.resolveComponentFactory(LoaderComponent);
            const componentRef = this.entry.createComponent(factory);
            this.fileDetailService.getDeleteHistory(this.modelData.fileNumber).subscribe(result => {
                this.deletedData = result;
                this.dataFileSource = new MatTableDataSource(this.deletedData.fileDetails);
                this.dataFileVolumeSource = new MatTableDataSource(this.deletedData.fileVolumeDetails);
                this.dataMfrAssociationSource = new MatTableDataSource(this.deletedData.mfrAssociationDetails);
                this.deletedHistory = result.success ? true : false;
                this.isDeletedFetch = false;
                componentRef.destroy();
            }, error => {
                this.dataFileSource = new MatTableDataSource([]);
                this.dataFileVolumeSource = new MatTableDataSource([]);
                this.dataMfrAssociationSource = new MatTableDataSource([]);
                this.deletedHistory = false;
                componentRef.destroy();
            });
        }
    }

    onStartDateChange(event) {
        this.editFileForm.patchValue({
            startDate: {
                year: event.standardDateModel.year,
                month: event.standardDateModel.month,
                day: event.standardDateModel.day
            }
        })
    };

    onCancel() {
        this.editFileForm.markAsUntouched();
        this.router.navigateByUrl(`/file-details/${this.modelData.fileRecId}/details`);
    }

    onViewVolume() {
        this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
        this.router.navigateByUrl(`/file-details/${this.modelData.fileRecId}/file-volume`);
    }

    ngOnInit(): void {
        this.activeLevel = 1;
        this.pageName = 'File Details';
        this.editFileForm = this.fb.group({
            accountNumber: ['', [Validators.required]],
            fileNumber: ['', [Validators.required]],
            fileType: [''],
            ownerPartySiteNumber: ['', [Validators.required]],
            subscriberNumber: [''],
            fileCcn: ['', [Validators.required]],
            startDate: ['', [Validators.required]],
        });
        this.setFormValues(this.modelData);
        this.isAdmin = this.dataService.getOption(Constants.IS_ADMIN_USER);
        this.isEdit = this.dataService.getOption(Constants.IS_EDIT_USER);
        this.isDeletedFetch = true;
    }

    ngAfterViewInit(): void {
        this.loadingSpinnerService.removeLoadingSpinner();
    }

    editFile(modelData: FileSummary) {
        let controlNumber: ControlNumber[];
        if (modelData.controlNumbers) {
            controlNumber = modelData.controlNumbers;
        }
        else {
            controlNumber = [
                {
                    controlNumberId: 0,
                    controlNumber: '',
                    fileRecId: null,
                    action: Constants.ADD
                },
            ];
        }
        const model: CreateEditFile = {
            fileRecId: modelData.fileRecId,
            fileType: modelData.fileType,
            ownerAccountNumber: modelData.ownerAccount,
            orgNumber: modelData.orgNumber,
            partySiteNumber: modelData.ownerPartySiteNumber,
            subscriberNumber: modelData.subscriberNumber,
            ownerCompanyName: modelData.ownerCompanyName,
            ownerCompanyAddress1: modelData.ownerCompanyAddress1,
            ownerCompanyAddress2: modelData.ownerCompanyAddress2,
            ownerCompanyAddress3: modelData.ownerCompanyAddress3,
            ownerCompanyAddress4: modelData.ownerCompanyAddress4,
            ownerCompanyAddress5: modelData.ownerCompanyAddress5,
            ownerCompanyAddress6: modelData.ownerCompanyAddress6,
            ownerPostalCode: modelData.ownerPostalCode,
            ownerCity: modelData.ownerCity,
            ownerState: modelData.ownerState,
            ownerCountry: modelData.ownerCountry,
            ownerProvince: modelData.ownerProvince,
            fileCcn: modelData.fileCcn,
            ownerFileNo: modelData.fileNumber,
            partyNumber: modelData.partyNumber,
            startDate: modelData.startDate,
            lastUpdatedBy: '',
            lastUpdatedOn: '',
            wdrwlStatus: 'A',
            wdrlRecId: 0,
            controlNumbers: controlNumber
        }
        this.sideNavService.createEditFile(model, 'edit');
    }

    addVolume(modelData: FileSummary) {
        const compCCN = {
            compCCNRecId: 0,
            compCCN: "",
            fileVolRecId: 0,
            action: Constants.ADD
        };
        const model: CreateEditFileVolume = {
            volumeType: '',
            volume: '',
            startDate: this.dataService.getCurrentDate(),
            endDate: null,
            wdrwlCode: '',
            basicCcn: '',
            complementaryCCN: [compCCN],
            ownerFileNo: modelData.fileNumber,
            ownerAccount: modelData.ownerAccount,
            ownerPartySiteNumber: modelData.ownerPartySiteNumber,
            volumeStatus: 'A',
            serviceContratctNumber: null,
            isListee: null,
            fileRecId: modelData.fileRecId,
            wdrlRecId: 0,
            markCode: null,
            respOfcRecId: null,
            respOffice: null,
            isSetUpComplete: null,
            lastUpdatedBy: this.dataService.getLastUpdatedBy(),
            lastUpdatedOn: this.dataService.getLastUpdatedDate(),
        };
        const wdrwlCodes = this.dataService.getOption(Constants.WDRWL_CODES);
        this.sideNavService.addVolume(model, wdrwlCodes);
    }

    onWithdrawOrDelete = (result: boolean, action: string) => {
        this.dataService.navigateToPageTop();
        if (result) {
            const successMessage = `The File detail was ${action.toLowerCase()} successfully`;
            const type = ToastNotificationType.success;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(successMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
            if (action === Constants.WITHDRAWN) { this.fileDetailService.onRefreshSearchResult(); }
            else { this.router.navigateByUrl(`/file-details`); }
        }
        else {
            const withdrawOrDeleteMsg = action === Constants.WITHDRAWN ? 'withdraw' : 'delete';
            const errorMessage = `Error while trying to ${withdrawOrDeleteMsg} the file. Please try again later`;
            const type = ToastNotificationType.error;
            const placement = ToastNotificationPlacement.Body;
            const message = new ToastNotificationMessage(errorMessage, type, placement);
            this.toastService.sendToastNotificationMessage(message);
        }
    }

    withdrawFile(fileRecId: number) {
        const wdrwlCodes = this.dataService.getOption(Constants.WDRWL_CODES);
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.withdrawFile(fileRecId, wdrwlCodes, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result == true) { this.onWithdrawOrDelete(true, Constants.WITHDRAWN); }
            else if (result == false) { this.onWithdrawOrDelete(false, Constants.WITHDRAWN); }
            else { /*DO Nothing*/ }
        });
    }

    deleteFile(fileRecId: number) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        this.withdrawOrDelete.deleteFile(fileRecId, componentRef).subscribe(result => {
            componentRef.destroy(); // To destroy the load spinner component
            if (result == true) { this.onWithdrawOrDelete(true, Constants.DELETE); }
            else if (result == false) { this.onWithdrawOrDelete(false, Constants.DELETE); }
            else { /*DO Nothing*/ }
        });
    }

    suspendFile(fileRecId: number, fileNumber: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: `Suspend File - ${fileNumber}`,
            confirmationInfo: `Are you sure you want to suspend the file?`,
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result
            .then(result => {
                const lastUpdatedBy = this.dataService.getLastUpdatedBy();
                const lastUpdatedDate = this.dataService.getLastUpdatedDate();
                const endDate = this.dataService.customDateFormat(null);
                const suspendRequest: FileSuspendRequest = {
                    fileRecId: fileRecId,
                    wdrlStatus: 'S',
                    fileVolStatus: 'S',
                    wdrlRecId: 0,
                    mfrStatus: 'S',
                    listeeIsActive: 'S',
                    lastUpdatedBy: lastUpdatedBy,
                    lastUpdatedOn: lastUpdatedDate,
                    endDate: endDate
                }

                this.fileDetailService.suspendFile(suspendRequest).subscribe(result => {
                    const returnCode = !isNaN(Number(result)) ? Number(result) : 99;
                    componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToPageTop();
                    if (returnCode == 1) {
                        const successMessage = `The ${fileNumber} file has been suspended successfully.`;
                        const type = ToastNotificationType.success;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(successMessage, type, placement, 10000);
                        this.toastService.sendToastNotificationMessage(message);
                        this.fileDetailService.onRefreshSearchResult();
                    }
                    else {
                        const errorMessage = `Error while trying to suspend the ${fileNumber} file.`;
                        const type = ToastNotificationType.error;
                        const placement = ToastNotificationPlacement.Body;
                        const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                        this.toastService.sendToastNotificationMessage(message);
                    }
                }, error => {
                    this.dataService.navigateToPageTop();
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    componentRef.destroy(); // To destroy the load spinner component
                    const errorMessage = `Error while trying to suspend the ${fileNumber} file.`;
                    const message = new ToastNotificationMessage(errorMessage, type, placement, 10000);
                    this.toastService.sendToastNotificationMessage(message);
                });
            }).catch(res => {
                if (res.action === 'no') {
                    componentRef.destroy(); // To destroy the load spinner component
                }
            });
    }

    formatControlNumber = (controlNumber) => {
        if (controlNumber) {
            let temp = "";
            controlNumber.forEach(data => {
                temp += `${data.controlNumber},`
            });
            return temp.substring(0, temp.length - 1);
        }
        return "";
    }

    validateDateFields() {
        let anyError = false;
        const startDate = this.editFileForm.value.startDate;
        if (this.dataService.checkIfDateIsEmpty(startDate)) {
            this.editFileForm.controls['startDate'].setErrors({ dateIsEmpty: true })
            anyError = true;
        }

        if (this.dataService.validateStandardDate(startDate)) {
            this.editFileForm.controls['startDate'].setErrors({ dateInvalid: true })
            anyError = true;
        }

        return anyError;
    }

    setFormValues = (data: FileSummary) => {
        this.editFileForm = this.fb.group({
            accountNumber: data.ownerAccount,
            fileNumber: data.fileNumber,
            fileType: data.fileType,
            ownerPartySiteNumber: data.ownerPartySiteNumber,
            subscriberNumber: data.subscriberNumber,
            fileCcn: data.fileCcn,
            startDate: this.dataService.getDate(data.startDate),
        });
    }

    getFileDetail = (fileRecId: number) => {
        this.fileDetailService.getFileDetails(fileRecId).subscribe((result) => {
            if (result) {
                this.dataService.setModelData(result);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.refreshSearchResultSubscription) {
            this.refreshSearchResultSubscription.unsubscribe();
        }
    }

    reactivateFile(fileRecId: number, fileNumber: string) {
        const factory = this.resolver.resolveComponentFactory(LoaderComponent);
        const componentRef = this.entry.createComponent(factory);
        const dialogRef = this.modalService.open(ConfirmDialogComponent, Constants.CONFIRM_MODAL_CONFIG);
        dialogRef.componentInstance.data = {
            title: 'Re-Activate File',
            confirmationInfo: 'Are you sure you want to re-activate the file?',
            cancelButton: 'No',
            confirmButton: 'Yes'
        };
        dialogRef.result.then(result => {
            this.fileDetailService.reactivateFile(fileRecId, this.dataService.getLastUpdatedBy()).subscribe(result => {
                componentRef.destroy(); // To destroy the load spinner component
                if (result) {
                    this.dataService.navigateToPageTop();
                    const successMessage = `The ${fileNumber} file was re-activated successfully.`;
                    const type = ToastNotificationType.success;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(successMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                    this.fileDetailService.onRefreshSearchResult();
                }
                else {
                    this.dataService.navigateToPageTop();
                    const errorMessage = `Error while trying to re-activate the ${fileNumber} file.`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
                }
            }, error => {
                    componentRef.destroy(); // To destroy the load spinner component
                    this.dataService.navigateToPageTop();
                    const errorMessage = `Error while trying to re-activate the ${fileNumber} file.`;
                    const type = ToastNotificationType.error;
                    const placement = ToastNotificationPlacement.Body;
                    const message = new ToastNotificationMessage(errorMessage, type, placement);
                    this.toastService.sendToastNotificationMessage(message);
            });
        }).catch(res => {
            if (res.action === 'no') {
                componentRef.destroy(); // To destroy the load spinner component
            }
        });
    }
}
